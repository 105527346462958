<template>
    <ckeditor
        v-model="text"
        :editor="editor"
        :config="editorConfig"
        :disabled="disabled"
    />
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
    name: 'TextEditor',
    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'fontSize',
                        'fontFamily',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                        'highlight',
                        '|',
                        'alignment',
                        '|',
                        'numberedList',
                        'bulletedList',
                        '|',
                        'indent',
                        'outdent',
                        '|',
                        'todoList',
                        'link',
                        'blockQuote',
                        //'imageUpload',
                        'insertTable',
                        '|',
                        'undo',
                        'redo'
                    ]
                }
            },
            text: this.value
        }
    },
    watch: {
        text () {
            this.$emit('input', this.text)
        }
    }
}
</script>
