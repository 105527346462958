export const menuItems = [
    {
        id: 'dashboard',
        label: 'menuitems.dashboards.text',
        icon: 'bx-home-circle',
        link: 'home'
    },
    {
        id: 'orders',
        label: 'menuitems.orders.text',
        icon: 'bx-shopping-bag',
        link: 'orders.index'
    },
    {
        id: 'shop',
        label: 'menuitems.shop.text',
        icon: 'bx-store',
        permission: 'products',
        subItems: [
            {
                id: 'shop-products',
                label: 'menuitems.shop.products',
                link: 'products.index',
                parentId: 'shop'
            },
            {
                id: 'shop-categories',
                label: 'menuitems.shop.categories',
                link: 'categories.index',
                parentId: 'shop'
            },
            {
                id: 'shop-custom-fields',
                label: 'menuitems.shop.custom-fields',
                link: 'custom-fields.index',
                parentId: 'shop'
            }
        ]
    },
    {
        id: 'users',
        label: 'menuitems.users.text',
        icon: 'bx-group',
        link: 'users.index',
        permission: 'users'
    },
    {
        id: 'configurations',
        label: 'menuitems.configuration.text',
        icon: 'bx-cog',
        role: 'Super Admin',
        subItems: [
            {
                id: 'configurations-roles',
                label: 'menuitems.configuration.roles',
                link: 'roles.index',
                parentId: 'configurations'
            },
            {
                id: 'configurations-connectors',
                label: 'menuitems.configuration.connectors',
                link: 'connectors.index',
                parentId: 'configurations'
            },
            {
                id: 'syncs',
                label: 'menuitems.configuration.syncs',
                link: 'syncs.index',
                parentId: 'configurations'
            }
        ]
    }
];
