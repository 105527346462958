export default {
    state: {
        lastFilter: {},
        lastUrls: []
    },
    getters: {
        lastUrl: state => {
            if ( !state.lastUrls.length ) {
                return null
            }

            return state.lastUrls[state.lastUrls.length - 1]
        }
    },
    mutations: {
        SET_LAST_FILTER (state, { page, filter }) {
            state.lastFilter[page] = filter
        },
        PUSH_LAST_URL (state, url) {
            state.lastUrls.push(url)
        },
        REMOVE_LAST_URL (state, url) {
            state.lastUrls = state.lastUrls.filter((item, index) => {
                return index + 1 !== state.lastUrls.length
            })
        }
    },
    actions: {
        setLastFilter({ commit }, { page, filter }) {
            commit('SET_LAST_FILTER', { page, filter })
        },
        pushLastUrl ({ commit }, url) {
            commit('PUSH_LAST_URL', url)
        },
        removeLastUrl ({ commit }, url) {
            commit('REMOVE_LAST_URL', url)
        }
    }
}
