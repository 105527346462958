<script>
import Layout from '../../../layouts/main'
import CategoryForm from '../../../components/shop/categories/form'
import CategoriesTable from '../../../components/shop/categories/table'

export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    components: { CategoriesTable, CategoryForm, Layout },
    data () {
        return {
            title: 'Categories'
        }
    }
}
</script>

<template>
    <Layout>
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">{{ $t(title) }}</h4>
                </div>
            </div>
            <div class="col-12">
                <b-card>
                    <div class="text-end">
                        <b-button variant="primary" size="sm" :href="$root.route('categories.create', { category_id: data.category.category_id })">
                            <i class="bx bx-plus" />
                        </b-button>
                    </div>
                    <category-form :category="data.category" :parent="data.parent" />
                </b-card>
                <b-card v-if="data.category.id">
                    <h4 class="title">{{ $t('Subcategories') }}</h4>
                    <div class="text-end">
                        <b-button variant="primary" size="sm" :href="$root.route('categories.create', { category_id: data.category.id })">
                            <i class="bx bx-plus" />
                        </b-button>
                    </div>
                    <categories-table :filter-parent="data.category.id" />
                </b-card>
            </div>
        </div>
    </Layout>
</template>
