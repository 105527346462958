<script>
import Layout from '../../../layouts/main'
import CategoriesTable from '../../../components/shop/categories/table'

export default {
    props: {
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    components: { CategoriesTable, Layout },
    data() {
        return {
            title: 'Categories'
        }
    }
}
</script>

<template>
    <Layout>
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">{{ $t(title) }}</h4>
                </div>
            </div>
            <div class="col-12">
                <b-card>
                    <div class="row">
                        <div class="col-xs-6 text-start">
                            <b-button v-if="data.filterParent" variant="primary" size="md" :href="$root.route('categories.index')">
                                <i class="bx bx-undo" />
                            </b-button>
                        </div>
                        <div class="col-xs-6 text-end">
                            <b-button variant="primary" size="md" :href="$root.route('categories.create')">
                                <i class="bx bx-plus" />
                            </b-button>
                        </div>
                    </div>

                    <categories-table :filter-parent="data.filterParent" />
                </b-card>
            </div>
        </div>
    </Layout>
</template>
