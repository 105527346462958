<script>
import Layout from '../../layouts/main'
import OrdersTable from '../../components/orders/table'
import OrderDetail from '../../components/orders/detail'
import orderMixin from '../../mixins/status.mixin'
import Swal from 'sweetalert2'

export default {
    props: {
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    components: { OrdersTable, OrderDetail, Layout },
    mixins: [ orderMixin ],
    data() {
        return {
            title: 'Orders',
            currentOrder: null
        }
    },
    computed: {
        statusVariant () {
            return this.currentOrder ? this.getVariantStatus(this.currentOrder.status) : null
        }
    },
    methods: {
        onShowOrderDetail ( order ) {
            this.currentOrder = order
            this.showModal()
        },
        onResetModal () {
            this.hideModal()
            this.currentOrder = null
        },
        onModalOk () {
            this.hideModal()
            this.currentOrder = null
            this.$refs.ordersTable.refresh()
        },
        showModal () {
            this.$refs.modalOrderDetail.show()
        },
        hideModal () {
            this.$refs.modalOrderDetail.hide()
        },
        onUpdateStatus ( status ) {
            this.updateOrderStatus(this.currentOrder, status, this.onUpdateStatusSuccess, this.onUpdateStatusFail)
        },
        onUpdateStatusSuccess ({ data }) {
            Swal.fire(this.$t(data), '', 'success').then(({}) => {
                this.onModalOk()
            })
        },
        onUpdateStatusFail ({ response }) {
            Swal.fire(this.$t(response.data), '', 'error').then(({}) => {})
        }
    }
}
</script>

<template>
    <Layout>
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">{{ $t(title) }}</h4>
                </div>
            </div>
            <div class="col-12">
                <b-card>
                    <orders-table ref="ordersTable" :status="data.status" @show="onShowOrderDetail" />
                </b-card>
            </div>
        </div>
        <b-modal
            ref="modalOrderDetail"
            size="xl"
            scrollable
            @hidden="onResetModal"
        >
            <template #modal-header="{ close }">
                <div v-if="currentOrder" class="container">
                    <b-row>
                        <b-col sm="6" md="6">
                            <b-dropdown
                                :text="$t(currentOrder.status)"
                                :variant="statusVariant"
                                dropup
                                size="md"
                            >
                                <b-dropdown-item
                                    v-for="item in data.status"
                                    :key="`status-${item}`"
                                    :active="item === currentOrder.status"
                                    @click="onUpdateStatus(item)"
                                >
                                    {{ $t(item) }}
                                </b-dropdown-item>
                            </b-dropdown>
                            {{ currentOrder.code }}
                        </b-col>
                        <b-col sm="6" md="6" class="text-end">
                            <b-button variant="link" @click="close()">
                                <i class="bx bx-x" />
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </template>
            <order-detail
                v-if="currentOrder"
                :order="currentOrder"
                hide-amount
            />
            <template #modal-footer>
                <div v-if="currentOrder" class="text-end">
                    <strong>{{ $t('Total') }}: {{ currentOrder.amount_parsed }}</strong>
                </div>
            </template>
        </b-modal>
    </Layout>
</template>
