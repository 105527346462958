<template>
    <apexchart
        v-if="loaded"
        ref="apexChart"
        type="donut"
        :options="donutOptions"
        :series="donutData.chartData"
        :height="height"
    />
</template>

<script>
import dashboardMixin from '../../mixins/dashboard.mixin'

export default {
    name: 'donutChart',
    props: {
        colors: {
            type: Array,
            required: false,
            default: null
        },
        height: {
            type: String,
            required: false,
            default: '200px'
        }
    },
    mixins: [ dashboardMixin ],
    data () {
        return {
            donutData: {
                chartData: [],
                labels: []
            },
            loaded: false
        }
    },
    computed: {
        donutOptions () {
            const options = {
                chart: {
                    events: {
                        legendClick: this.handleLegendClick
                    }
                },
                labels: this.donutData.labels,
                legend: {
                    position: 'top'
                }
            }

            if ( this.colors ) {
                options.colors = this.colors
            }

            return options
        }
    },
    methods: {
        handleLegendClick (chartContext, seriesIndex, config) {
            this.$emit('onLegendClick', this.labels[seriesIndex])
        },
        onSuccess ({ data }) {
            this.setData(data)
            this.loaded = true
        },
        setData ( items ) {
            const datasets = []
            const labels = []

            for ( const key of Object.keys(items) ) {
                labels.push(this.$t(key))
                datasets.push(items[key])
            }

            this.donutData.chartData = datasets
            this.donutData.labels = labels
        }
    },
    mounted () {
        this.loadData(this.onSuccess)
    }
}
</script>
