<template>
    <b-row>
        <b-col
            v-for="salesChannel of salesChannels"
            :key="`sales-channel-${salesChannel.name}`"
            :sm="colSm"
            :md="colMd"
        >
            <b-card>
                <h4 class="card-title">{{ salesChannel.name }} <small>({{ $t('Last month') }})</small></h4>
                <b-row>
                    <b-col sm="8">
                        <apexchart
                            ref="apexChart"
                            type="radialBar"
                            :options="salesChannel.chartOptions"
                            :series="salesChannel.chartSeries"
                        />
                    </b-col>
                    <b-col sm="4">
                        <div class="text-center">
                            <strong>{{ $t('Total') }}:</strong><br>{{ salesChannel.total | amount }}
                            <hr>
                            <strong>{{ $t('Average ticket') }}:</strong><br>{{ (salesChannel.total / salesChannel.count) | amount }}
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import dashboardMixin from '../../mixins/dashboard.mixin'

export default {
    name: 'SalesChannels',
    props: {
        colSm: {
            type: Number,
            required: false,
            default: 12
        },
        colMd: {
            type: Number,
            required: false,
            default: 6
        }
    },
    mixins: [ dashboardMixin ],
    data () {
        return {
            salesChannels: [],
            totalSales: 0,
            optionsChart : {
                labels: ['']
            }
        }
    },
    methods: {
        onSuccess ({ data }) {
            this.totalSales = this.getTotal(data)
            this.salesChannels = data.map(item => {
                const percentage = (item.count / this.totalSales * 100).toFixed(0)
                const color = percentage > 66
                    ? '#3fc694'
                    : (percentage > 33
                        ? '#f1b54d'
                        : '#f46e6e'
                    )
                return {
                    ...item,
                    chartSeries: [percentage],
                    chartOptions: {
                        labels: [item.name],
                        colors: [color]
                    }
                }
            })
        },
        getTotal (salesChannels) {
            let total = 0

            for ( const salesChannel of salesChannels ) {
                total += salesChannel.count
            }

            return total
        }
    },
    mounted () {
        this.loadData(this.onSuccess)
    }
}
</script>
