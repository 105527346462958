<template>
    <Layout>
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">{{ $t(title) }}</h4>
                </div>
            </div>
        </div>
        <b-card>
            <user-form :user="data.user" :roles="data.roles" />
        </b-card>
    </Layout>
</template>

<script>
import Layout from '../layouts/main'
import UserForm from '../components/user/form'

export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    components: { Layout, UserForm },
    data() {
        return {
            title: 'Profile'
        }
    }
}
</script>
