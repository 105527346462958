import axios from 'axios'

export default {
    computed: {
        variantStatus () {
            return {
                'created': 'warning',
                'accepted': 'warning',
                'processed': 'info',
                'shipping': 'info',
                'received': 'success',
                'refund': 'danger',
                'cancelled': 'danger'
            }
        }
    },
    methods: {
        getVariantStatus ( status ) {
            return this.variantStatus[status] ?? 'info'
        },
        updateOrderStatus ( order, status, onSuccess, onFail ) {
            axios.put(this.$root.route('orders.update', order.id), {
                _token: this.csrf,
                status
            })
                .then(onSuccess)
                .catch(onFail)
        }
    }
}
