<template>
    <div>
        <b-row>
            <b-col
                v-for="param in syncModel.params"
                :key="`sync-param-${param.name}`"
                sm="6"
            >
                <input-sync-form
                    v-model="syncParams[param.name]"
                    :name="param.name"
                    :label="param.label"
                    :type="param.type"
                    :data="{ connectors, options: param.options }"
                />
            </b-col>
        </b-row>
        <b-row v-if="extraInputs.length">
            <b-col
                v-for="extraInput in extraInputs"
                :key="extraInput.name"
                sm="6"
            >
                <b-form-group
                    class="mb-3"
                    :label="`${$t(extraInput.label)} *`"
                    :label-for="extraInput.name"
                >
                    <b-form-input
                        :id="extraInput.name"
                        v-model="extraInput.value"
                        :name="extraInput.name"
                        :type="extraInput.type"
                        :class="{'is-invalid': errors[`${extraInput.name}.${extraInput.param}`]}"
                    />
                    <div v-if="errors[`${extraInput.name}.${extraInput.param}`]" class="invalid-feedback">
                        {{ errors[`${extraInput.name}.${extraInput.param}`][0] }}
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <div v-if="nextExecution">
            <small>{{ $t('Next execution') }}: {{ nextExecution }}</small>
        </div>
        <hr>
        <div class="text-end">
            <b-button class="btn btn-success" @click="onSave">
                {{ $t('Save') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import InputSyncForm from './input'
import axios from 'axios'
import { mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
    name: 'SyncForm',
    props: {
        setting: {
            type: Object,
            required: true
        },
        syncModel: {
            type: Object,
            required: true
        },
        connectors: {
            type: Array,
            required: true
        },
        extraForm: {
            type: Object,
            required: false,
            default: null
        },
        nextExecution: {
            type: String,
            required: false,
            default: null
        }
    },
    components: { InputSyncForm },
    data () {
        return {
            syncParams: this.setting.params,
            extraInputs: [],
            errors: []
        }
    },
    computed: {
        ...mapState(['csrf']),
    },
    methods: {
        loadExtraInputs () {
            this.extraInputs = []

            if ( this.extraForm && this.extraForm.inputs.length ) {
                for ( const input of this.extraForm.inputs ) {
                    this.extraInputs.push({
                        ...input,
                        value: this.extraForm.data.params[input.param] ?? ''
                    })
                }
            }
        },
        onSave () {
            const data = {
                _token: this.csrf,
                params: this.syncParams
            }

            if ( this.extraInputs.length ) {
                for ( const extraInput of this.extraInputs ) {
                    if ( !data[extraInput.name] ) {
                        data[extraInput.name] = {}
                    }

                    data[extraInput.name][extraInput.param] = extraInput.value
                }
            }

            axios.put( this.$root.route('settings.update', { setting: this.setting.id }), data)
                .then(this.onUpdateSuccess)
                .catch(this.onSaveFail)
        },
        onUpdateSuccess ({ data }) {
            Swal.fire(this.$t(data.message), '', 'success').then(({}) => {
                this.errors = []
            })
        },
        onSaveFail ( { response }) {
            this.errors = response.data.errors
        }
    },
    mounted () {
        this.loadExtraInputs()
    }
}
</script>
