<template>
    <div>
        <vuetable
            ref="rolesTable"
            :fields="fields"
            :api-url="$root.route('roles.data')"
            count-table-data
        >
            <template slot="permissions" slot-scope="props">
                <b-badge
                    v-for="permission in permissions"
                    :key="`permission-${permission.name}`"
                    :variant="getRoleHasPermission(props.rowData, permission) ? 'soft-success' : 'soft-danger'"
                    class="me-2"
                >
                    {{ permission.name }}
                </b-badge>
            </template>
            <template slot="actions" slot-scope="props">
                <b-button-group>
                    <b-button
                        variant="primary"
                        size="sm"
                        :href="$root.route('roles.edit', { role: props.rowData.id })"
                        :disabled="props.rowData.name === 'Super Admin'"
                    >
                        <i class="bx bx-pencil" />
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        :disabled="props.rowData.name === 'Super Admin'"
                        @click="onDelete(props.rowData.id)"
                    >
                        <i class="bx bx-trash" />
                    </b-button>
                </b-button-group>
            </template>
        </vuetable>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import Swal from 'sweetalert2'
import axios from 'axios'

export default {
    name: 'RolesTable',
    props: {
        permissions: {
            type: Array,
            required: true
        }
    },
    components: { Vuetable },
    computed: {
        ...mapState(['csrf']),
        fields () {
            return [
                {
                    name: 'id',
                    title: '#'
                },
                {
                    name: 'name',
                    title: this.$t('Name')
                },
                {
                    name: '__slot:permissions',
                    title: this.$t('Permissions')
                },
                {
                    name: '__slot:actions',
                    title: '',
                    dataClass: 'text-end'
                }
            ]
        }
    },
    methods: {
        onDelete ( role ) {
            Swal.fire({
                title: this.$t('Are you sure?'),
                text: this.$t('This action remove this role'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes, delete it!'),
                cancelButtonText: this.$t('No, keep it')
            }).then(({ isConfirmed }) => {
                if ( isConfirmed ) {
                    axios.delete(
                        this.$root.route('roles.destroy', { role }),
                        {
                            _token: this.csrf,
                        }
                    ).then(this.onDeletedSuccess)
                }
            })
        },
        onDeletedSuccess ({ data }) {
            this.$refs.rolesTable.refresh()
            Swal.fire(data, '', 'success')
        },
        getRoleHasPermission ( role, permission ) {
            let hasPermission = false

            role.permissions.forEach(rolePermission => {
                if ( rolePermission.id === permission.id ) {
                    hasPermission = true
                }
            })

            return hasPermission
        }
    }
}
</script>
