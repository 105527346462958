<template>
    <b-button
        variant="outline-primary"
        size="sm"
        @click="exportCsv"
    >
        <i class="bx bx-cloud-download font-size-18" />
    </b-button>
</template>

<script>
export default {
    name: 'ExportButton',
    props: {
        url: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            filter: null
        }
    },
    methods: {
        exportCsv () {
            window.open(this.getUrlToExport(), '_blank')
        },
        getUrlToExport () {
            let url = this.url
            let isFirstFilter = true

            if ( this.filter && Object.keys(this.filter).length ) {
                for ( const filterKey of Object.keys(this.filter) ) {
                    url += `${isFirstFilter ? '?' : '&'}${filterKey}=${encodeURI(this.filter[filterKey])}`
                    isFirstFilter = false
                }
            }

            return url
        },
        updateFilter ( filter ) {
            this.filter = filter
        }
    }
}
</script>
