import Vue from 'vue';
// Use it to import all Vue file containing this folder as Components
// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('home', require('./home').default);
Vue.component('roles', require('./roles/index').default);
Vue.component('roles-form', require('./roles/form').default);
Vue.component('users', require('./users/index').default);
Vue.component('users-form', require('./users/form').default);
Vue.component('profile', require('./profile').default);
Vue.component('categories', require('./shop/categories/index').default);
Vue.component('categories-form', require('./shop/categories/form').default);
Vue.component('products', require('./shop/products/index').default);
Vue.component('products-detail', require('./shop/products/detail').default);
Vue.component('products-form', require('./shop/products/form').default);
Vue.component('custom-fields', require('./shop/custom-fields/index').default);
Vue.component('orders', require('./orders/index').default);
Vue.component('connectors', require('./connectors/index').default);
Vue.component('connectors-form', require('./connectors/form').default);
Vue.component('syncs-index', require('./syncs').default);
Vue.component('connectors-index', require('./connectors').default);
Vue.component('connectors-form', require('./connectors/form').default);
// Auth
Vue.component('login', require('./account/login').default);
Vue.component('register', require('./account/register').default);
Vue.component('forgot-password', require('./account/forgot-password').default);
Vue.component('reset-password', require('./account/reset-password').default);
// Resources
Vue.component('pagination', require('./../components/resources/pagination').default);
Vue.component('text-editor', require('./../components/resources/textEditor').default);

