<template>
    <div>
        <b-row>
            <b-col sm="12" md="6">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Name')} *`"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="categoryData.name"
                        name="name"
                        type="text"
                        :class="{'is-invalid': errors.name}"
                    />
                    <div v-if="errors.name" class="invalid-feedback">
                        {{ errors.name[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Parent')}`"
                    label-for="parent"
                >
                    <div v-if="parent" v-html="parent.breadcrumb_linked" />
                </b-form-group>
            </b-col>
        </b-row>
        <hr>
        <div class="text-end">
            <b-button class="btn btn-success" @click="onSave">
                {{ $t('Save') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    name: 'CategoryForm',
    props: {
        category: {
            type: Object,
            required: true
        },
        parent: {
            type: Object,
            required: false,
            default: null
        }
    },
    data () {
        const { id, name, category_id } = this.category

        return {
            categoryData: { id, name, category_id },
            errors: {}
        }
    },
    computed: {
        ...mapState(['csrf', 'auth']),
        isNew () {
            return !this.category.id
        }
    },
    methods: {
        onSave () {
            this.errors = {}

            if ( this.isNew ) {
                axios.post(this.$root.route('categories.store'), {
                    _token: this.csrf,
                    ...this.categoryData
                })
                    .then(this.onCreateSuccess)
                    .catch(this.onSaveFail)
            } else {
                axios.put(this.$root.route('categories.update', this.category.id), {
                    _token: this.csrf,
                    ...this.categoryData
                })
                    .then(this.onUpdateSuccess)
                    .catch(this.onSaveFail)
            }
        },
        onCreateSuccess({ data }) {
            Swal.fire(this.$t(data.message), '', 'success').then(({}) => {
                window.location.href = this.$root.route('categories.edit', { category: data.data.id })
            })
        },
        onUpdateSuccess ({ data }) {
            Swal.fire(this.$t(data.message), '', 'success').then(({}) => {
                window.location.reload()
            })
        },
        onSaveFail ( { response }) {
            const { errors } = response.data

            if ( errors ) {
                this.errors = errors
            }
        }
    }
}
</script>
