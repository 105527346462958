<template>
    <div>
        <h4>{{ $t(variant.id ? 'Update variant' : 'Create variant')}}</h4>
        <hr>
        <b-row>
            <b-col sm="12" md="6">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('ASIN')} *`"
                    label-for="asin"
                >
                    <b-form-input
                        id="asin"
                        v-model="variantData.asin"
                        name="asin"
                        type="text"
                        :class="{'is-invalid': errors.asin}"
                    />
                    <div v-if="errors.asin" class="invalid-feedback">
                        {{ errors.asin[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="6" md="3">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Stock')} *`"
                    label-for="stock"
                >
                    <b-form-input
                        id="stock"
                        v-model="variantData.stock"
                        name="stock"
                        type="number"
                        :class="{'is-invalid': errors.stock}"
                    />
                    <div v-if="errors.stock" class="invalid-feedback">
                        {{ errors.stock[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="6" md="3">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Price')} *`"
                    label-for="price"
                >
                    <b-form-input
                        id="price"
                        v-model="variantData.price"
                        name="price"
                        type="number"
                        step=0.01
                        :class="{'is-invalid': errors.price}"
                    />
                    <div v-if="errors.price" class="invalid-feedback">
                        {{ errors.price[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="12">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('SKU')}`"
                    label-for="skus"
                >
                    <b-form-input
                        id="skus"
                        v-model="sku"
                        name="skus"
                        separator=" ,;"
                        @keyup.enter="onSetSku"
                    />
                </b-form-group>
                <div v-if="variantData.skus.length">
                    <span
                        v-for="(sku, index) in variantData.skus"
                        :key="`sku-tag-${sku}`"
                        class="badge badge-pill badge-soft-primary me-2"
                        :class="errors[`skus.${index}`] ? ' badge-soft-danger' : ' badge-soft-primary'"
                    >
                        {{ sku }}
                        <button class="btn btn-sm btn-link" @click="onRemoveSku(sku)">
                            <i class="bx bx-x" />
                        </button>
                    </span>
                </div>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col sm="6">
                <button class="btn btn-md btn-secondary" @click="$emit('cancel')">
                    {{ $t('Cancel') }}
                </button>
            </b-col>
            <b-col sm="6" class="text-end">
                <button class="btn btn-md btn-success" @click="onSave">
                    {{ $t('Save') }}
                </button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    name: 'ProductVariantForm',
    props: {
        variant: {
            type: Object,
            required: true
        }
    },
    data () {
        const { id, product_id, asin, stock, price, skus } = this.variant

        return {
            variantData: { id, product_id, asin, stock, price, skus: skus.map(sku => sku.value) },
            sku: '',
            errors: {}
        }
    },
    computed: {
        isNew () {
            return !this.variant.id
        }
    },
    methods: {
        onSetSku () {
            if ( !this.variantData.skus.includes(this.sku) ) {
                this.variantData.skus.push(this.sku)
                this.sku = ''
            }
        },
        onRemoveSku ( value ) {
            this.variantData.skus = this.variantData.skus.filter(sku => sku !== value)
        },
        onSave () {
            this.errors = {}

            if ( this.isNew ) {
                axios.post(this.$root.route('variants.store'), {
                    _token: this.csrf,
                    ...this.variantData
                })
                    .then(this.onSuccess)
                    .catch(this.onSaveFail)
            } else {
                axios.put(this.$root.route('variants.update', this.variant.id), {
                    _token: this.csrf,
                    ...this.variantData
                })
                    .then(this.onSuccess)
                    .catch(this.onSaveFail)
            }
        },
        onSuccess({ data }) {
            Swal.fire(this.$t(data.message), '', 'success').then(({}) => {
                this.$emit('refresh')
            })
        },
        onSaveFail ( { response }) {
            const { errors } = response.data

            if ( errors ) {
                this.errors = errors
            }
        }
    }
}
</script>
