<script>
import NavBar from '../components/nav-bar'
import SideBar from '../components/side-bar'
import RightBar from '../components/right-bar'
import Footer from '../components/footer'
import { mapActions, mapState } from 'vuex'

export default {
    components: { NavBar, SideBar, RightBar, Footer },
    computed: {
        ...mapState(['layout']),
        collapse () {
            return this.layout.collapse
        }
    },
    watch: {
        collapse () {
            this.updateBodyClass()
        }
    },
    methods: {
        ...mapActions({
            setCollapse: 'setCollapse'
        }),
        toggleMenu() {
            this.setCollapse(!this.layout.collapse)
        },
        updateBodyClass () {
            const bodyClass = 'sidebar-enable'
            const bodyCollapsedClass = 'vertical-collpsed'

            if ( this.collapse ) {
                document.body.classList.remove(bodyClass)
                document.body.classList.add(bodyCollapsedClass)
            } else {
                document.body.classList.add(bodyClass)
                if ( window.screen.width <= 992 ) {
                    document.body.classList.add(bodyCollapsedClass)
                } else {
                    document.body.classList.remove(bodyCollapsedClass)
                }
            }
        },
        toggleRightSidebar() {
            document.body.classList.toggle('right-bar-enabled')
        },
        hideRightSidebar() {
            document.body.classList.remove('right-bar-enabled')
        }
    },
    created () {
        document.body.removeAttribute('data-layout', 'horizontal')
        document.body.removeAttribute('data-topbar', 'dark')
        document.body.removeAttribute('data-layout-size', 'boxed')
    },
    mounted () {
        this.updateBodyClass()
    }
}
</script>

<template>
    <div id="layout-wrapper">
        <NavBar/>
        <SideBar :is-condensed="collapse"/>
        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="main-content">
            <div class="page-content">
                <!-- Start Content-->
                <div class="container-fluid">
                    <slot/>
                </div>
            </div>
            <Footer/>
        </div>
        <RightBar/>
    </div>
</template>
