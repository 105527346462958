import axios from 'axios'

export default {
    computed: {
      productFilterErrorOptions () {
          return [
              {
                  value: 'all',
                  text: this.$t('With errors & without errors')
              },
              {
                  value: 'only-success',
                  text: this.$t('Only success')
              },
              {
                  value: 'only-errors',
                  text: this.$t('Only errors')
              }
          ]
      }
    },
    methods: {
        getDefaultProductFilterErrorsFilter () {
            const statusFilter = this.$router.history.current.query.status

            if ( !statusFilter || statusFilter === 'all' || !this.productFilterErrorOptions.some(({ value }) => value === `only-${statusFilter}`) ) {
                return 'all'
            }

            return `only-${statusFilter}`
        },
        syncProduct ( product_id, onSuccess, onError ) {
            axios.post(this.$root.route('products.download', product_id))
                .then(() => {
                    axios.post(this.$root.route('products.sync', product_id))
                        .then(onSuccess)
                        .catch(onSuccess)
                })
                .catch(onError)
        }
    }
}
