export default {
    table: {
        css: {
            ascendingIcon: 'bx bx-sort-up',
            descendingIcon: 'bx bx-sort-down',
            detailRowClass: 'vuetable-detail-row',
            loadingClass: 'loading',
            sortHandleIcon: 'grey sidebar icon',
            tableClass: 'ui blue selectable celled stackable attached table'
        }
    },
    pagination: {
        css: {
            activeClass: 'active large',
            disabledClass: 'disabled',
            linkClass: 'icon page-item page-link',
            pageClass: 'page-item page-link',
            paginationClass: 'ui bottom attached segment grid',
            paginationInfoClass: 'left floated left aligned six wide column',
            wrapperClass: 'pagination pagination-rounded pagination-sm float-end',
        },
        icons: {
            first: 'bx bx-chevrons-left',
            last: 'bx bx-chevrons-right',
            next: 'bx bx-chevron-right',
            prev: 'bx bx-chevron-left'
        }
    }
}
