<template>
    <div>
        <div v-if="imagesImported.length">
            <h5>{{ $t('Imported images from') }} {{ product.import_origin }}</h5>
            <b-row class="mb-2">
                <b-col
                    v-for="imageImported in imagesImported"
                    :key="`image-imported-${imageImported.id}`"
                    sm="4"
                    md="1"
                >
                    <b-img
                        :src="imageImported.url"
                        fluid
                    />
                    <b-button
                        variant="light"
                        size="md"
                        block
                        @click="onDownload(imageImported.url)"
                    >
                        <i class="bx bxs-download" />
                        {{ $t('Download') }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <div>
            <div v-if="productImages.length" class="product-detail-imgs">
                <b-tabs pills card vertical>
                    <b-tab
                        v-for="(image, index) of productImages"
                        :key="`product-image-${image.id}`"
                        :active="image.id === imageCurrent"
                    >
                        <template #title>
                            <b-img :src="image.url" width="50" />
                        </template>
                        <div>
                            <b-row class="bg-light p-2">
                                <b-col sm="3">
                                    <div class="d-inline-block">
                                        <i
                                            class="bx bx-chevron-up d-block icon-button"
                                            :class="{'text-muted icon-button-disabled': index < 1}"
                                            @click="onSorting(index, 'up')"
                                        />
                                        <i
                                            class="bx bx-chevron-down d-block icon-button"
                                            :class="{'text-muted icon-button-disabled': index + 1 >= productImages.length}"
                                            @click="onSorting(index, 'down')"
                                        />
                                    </div>
                                    <b-badge variant="soft-primary" class="align-top mt-1">
                                        {{ $t('Image') }} {{ index + 1 }}
                                    </b-badge>
                                </b-col>
                                <b-col sm="6">
                                    <b-form-checkbox-group
                                        v-model="image.marketplaces"
                                        :options="marketplaces.list"
                                        text-field="label"
                                        value-field="key"
                                        class="form-check form-check-inline"
                                        @change="updateImageMarketplaces(image)"
                                    />
                                </b-col>
                                <b-col sm="3" class="text-end">
                                    <b-button
                                        variant="danger"
                                        size="sm"
                                        @click="onDelete(image)"
                                    >
                                        <i class="bx bx-trash" />
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-img-lazy :src="image.url" style="width: 100%" />
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
            <b-alert
                :show="!productImages.length"
                variant="warning"
            >
                {{ $t('Images not found') }}
            </b-alert>
        </div>
        <div class="mt-5">
            <vue2-dropzone
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                duplicate-check
                @vdropzone-success="onStored"
            />
        </div>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
    name: 'ProductImagesForm',
    props: {
        product: {
          type: Object,
          required: true
        }
    },
    components: { vue2Dropzone },
    data () {
        return {
            productImages: this.$root.clone(this.product.images ?? []).map(image => {
                image.marketplaces = image.marketplaces ?? []
                return image
            }),
            imageCurrent: this.product.images && this.product.images.length ? this.$root.clone(this.product.images[0].id) : null
        }
    },
    computed: {
        ...mapState(['csrf', 'auth', 'marketplaces']),
        dropzoneOptions () {
            return {
                url: this.$root.route('product-images.store'),
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                headers: { "My-Awesome-Header": "header value" },
                acceptedFiles: 'image/*',
                params: {
                    _token: this.csrf,
                    product_id: this.product.id
                }
            }
        },
        imagesImported () {
            if ( !this.product.import_data.images ) {
                return []
            }

            return this.product.import_data.images.filter(image => {
                return !this.productImages.some(productImage => productImage.original === image.url)
            })
        }
    },
    mounted () {
        this.$emit('refreshed', this.productImages)
    },
    methods: {
        onStored ( file, response ) {
            this.$refs.myVueDropzone.removeAllFiles()
            this.refreshList(response)
        },
        onDelete ( image ) {
            axios.delete(this.$root.route('product-images.destroy', image.id), {
                _token: this.csrf
            }).then(({ data }) => {
                if ( this.imageCurrent === image.id ) {
                    this.refreshImageCurrent()
                }
                this.refreshList(data)
            })
        },
        onDownload ( url ) {
            axios.post(this.$root.route('products.generateImage', this.product.id), {
                _token: this.csrf,
                url
            }).then(({ data }) => {
                this.refreshList(data)
            })
        },
        onSorting ( index, direction ) {
            if ( (direction === 'up' && index > 0) || (direction === 'down' && index + 1 < this.productImages.length) ) {
                const image = this.productImages[index]

                axios.put(this.$root.route('product-images.sorting', image.id), {
                    _token: this.csrf,
                    direction
                }).then(({ data }) => {
                    this.refreshList(data)
                })
            }
        },
        updateImageMarketplaces ({ id, marketplaces }) {
            console.log(id, marketplaces)
            axios.put(this.$root.route('product-images.updateMarketplaces', id), {
                _token: this.csrf,
                marketplaces
            }).then(({ data }) => {
                console.log(data)
            })
        },
        refreshImageCurrent () {
            this.imageCurrent = this.productImages && this.productImages.length ? this.$root.clone(this.productImages[0].id) : null
        },
        refreshList ( images ) {
            console.log(images)
            this.productImages = images.map(image => {
                image.marketplaces = image.marketplaces ?? []
                return image
            })
            this.$emit('refreshed', images)
        }
    }
}
</script>

<style scoped>
.product-detail-imgs .tab-pane.card-body {
    padding: 0;
}
</style>
