<script>
import Layout from '../../../layouts/main'
import CustomFieldsTable from '../../../components/shop/custom-fields/table'
import CustomFieldForm from '../../../components/shop/custom-fields/form'

export default {
    props: {
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    components: { CustomFieldsTable, CustomFieldForm, Layout },
    data () {
        return {
            title: 'Custom fields',
            form: {
                visible: false,
                customField: null
            }
        }
    },
    methods: {
        handlerCreate () {
            this.form.customField = null
            this.form.visible = true
        },
        handlerEdit ( customField ) {
            this.form.customField = customField
            this.form.visible = true
        },
        handlerSaved () {
            this.form.visible = false
            this.$refs.customFieldsTable.refresh()
        }
    }
}
</script>

<template>
    <Layout>
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">{{ $t(title) }}</h4>
                </div>
            </div>
            <div class="col-12">
                <b-card>
                    <div class="row">
                        <div class="col-xs-6 text-end">
                            <b-button variant="primary" size="md" @click="handlerCreate">
                                <i class="bx bx-plus" />
                            </b-button>
                        </div>
                    </div>

                    <custom-fields-table ref="customFieldsTable" @edit="handlerEdit" />
                </b-card>
            </div>
        </div>
        <b-modal v-model="form.visible" :title="$t('Custom field')" centered hide-footer>
            <custom-field-form
                :custom-field="form.customField"
                @saved="handlerSaved"
            />
        </b-modal>
    </Layout>
</template>
