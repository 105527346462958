<template>
    <div>
        <b-row v-if="customFields.length">
            <b-col :sm="6" :md="9">
                <h5>{{ $t('Custom fields') }}</h5>
            </b-col>
            <b-col :sm="6" :md="3" class="text-end">
                <b-button
                    variant="primary"
                    size="md"
                    @click="handlerCreate"
                >
                    <i class="bx bx-plus" />
                </b-button>
            </b-col>
        </b-row>

        <div v-if="productCustomFields.length">
            <b-button
                v-for="productCustomField in productCustomFields"
                :key="`product-custom-field-${productCustomField.id}`"
                variant="primary"
                class="m-2"
                :disabled="productCustomField.imported"
                @click="handlerEdit(productCustomField)"
            >
                <span>{{ productCustomField.custom_field.name }} | {{ productCustomField.value }}</span>
            </b-button>
        </div>

        <b-modal v-model="form.visible" :title="$t('Custom field')" centered hide-footer>
            <product-custom-field-form
                v-if="customFields.length"
                :product="product"
                :custom-fields="customFields"
                :product-custom-fields="productCustomFields"
                :product-custom-field="form.productCustomField"
                @refresh="handlerRefresh"
            />
        </b-modal>
    </div>
</template>

<script>
import ProductCustomFieldForm from './form'
import axios from 'axios'

export default {
    name: 'ProductCustomFieldIndex',
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    components: { ProductCustomFieldForm },
    data () {
        return {
            customFields: [],
            productCustomFields: [],
            form: {
                visible: false,
                productCustomField: null
            }
        }
    },
    methods: {
        loadCustomFields () {
            axios.get(this.$root.route('custom-fields.data'), {
                params: {
                    all: true
                }
            })
                .then(({ data }) => {
                    this.customFields = data
                })
        },
        loadProductCustomFields () {
            axios.get(this.$root.route('product-custom-fields.index'), {
                params: {
                    product_id: this.product.id
                }
            })
                .then(({ data }) => {
                    this.productCustomFields = data
                })
        },
        handlerCreate () {
            this.form.productCustomField = null
            this.form.visible = true
        },
        handlerEdit ( productCustomField ) {
            this.form.productCustomField = productCustomField
            this.form.visible = true
        },
        handlerRefresh () {
            this.loadProductCustomFields()
            this.form.visible = false
        }
    },
    created () {
        this.loadCustomFields()
        this.loadProductCustomFields()
    }
}
</script>
