<template>
    <multiselect
        v-model="categorySelected"
        :placeholder="$t(this.placeholder)"
        :options="optionCategories"
        label="breadcrumb"
        trackBy="breadcrumb"
        :preserve-search="true"
        :searchable="true"
        :loading="isLoading"
        :optionsLimit="100"
    />
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    name: 'SelectCategory',
    props: {
        value: {
            type: Object,
            required: false,
            default: null
        },
        categories: {
            type: Array,
            required: false,
            default: []
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        }
    },
    components: { Multiselect },
    data () {
        return {
            categorySelected: this.value,
            isLoading: false
        }
    },
    computed: {
        optionCategories () {
            return [
                {
                    id: '',
                    breadcrumb: this.$t('Unselected')
                },
                ...this.categories
            ]
        }
    },
    watch: {
        categorySelected () {
            this.$emit('input', this.categorySelected)
        }
    }
}
</script>
