export default {
    state: {
        list: []
    },
    mutations: {
        SET_MARKETPLACES(state, marketplaces) {
            state.list = marketplaces
        }
    },
    actions: {
        setMarketplaces({ commit }, marketplaces) {
            commit('SET_MARKETPLACES', marketplaces)
        }
    }
}
