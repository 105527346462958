<script>
/**
 * Login component
 */
export default {
    data() {
        return {
            email: '',
            password: '',
            tryingToLogIn: false,
            isAuthError: false
        }
    },
    props: {
        submitUrl: {
            type: String,
            required: true
        },
        authError: {
            type: String,
            required: false,
            default: () => null
        }
    },
    mounted() {
        this.isAuthError = !!this.authError
    }
}
</script>

<template>
    <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
                <div class="bg-soft bg-primary">
                    <div class="row">
                        <div class="col-7">
                            <div class="text-primary p-4">
                                <h5 class="text-primary">{{ $t('Welcome Back !') }}</h5>
                                <p>{{ $t('Sign in to continue') }}</p>
                            </div>
                        </div>
                        <div class="col-5 align-self-end">
                            <img alt class="img-fluid" src="/images/profile-img.png"/>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <b-alert
                        v-model="isAuthError"
                        class="mt-3"
                        dismissible
                        variant="danger"
                    >
                        {{ authError }}
                    </b-alert>

                    <b-form :action="submitUrl" class="p-2" method="POST">
                        <slot/>
                        <b-form-group
                            id="input-group-1"
                            class="mb-3"
                            label="Email"
                            label-for="input-1"
                        >
                            <b-form-input
                                id="input-1"
                                v-model="email"
                                name="email"
                                placeholder="Enter email"
                                type="text"
                            />
                        </b-form-group>

                        <b-form-group
                            id="input-group-2"
                            class="mb-3"
                            label="Password"
                            label-for="input-2"
                        >
                            <b-form-input
                                id="input-2"
                                v-model="password"
                                name="password"
                                placeholder="Enter password"
                                type="password"
                            />
                        </b-form-group>
                        <b-form-checkbox
                            id="customControlInline"
                            class="form-check"
                            name="checkbox-1"
                            unchecked-value="not_accepted"
                            value="accepted"
                        >
                            {{ $t('Remember me') }}
                        </b-form-checkbox>
                        <div class="mt-3 d-grid">
                            <b-button class="btn-block" type="submit" variant="primary">
                                {{ $t('Log in') }}
                            </b-button>
                        </div>
                        <div class="mt-4 text-center">
                            <a :href="$root.route('password.request')" class="text-muted">
                                <i class="mdi mdi-lock mr-1"></i> {{ $t('Forgot your password?') }}
                            </a>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </div>
</template>

