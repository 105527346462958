<template>
    <b-row v-if="loaded">
        <b-col sm="6" md="3">
            <b-form-input
                v-model="filter.code"
                :placeholder="$t('Code, ASIN, SKU, ERP ID, Tracking...')"
                size="md"
                v-debounce="onFilter"
            />
        </b-col>
        <b-col sm="6" md="3">
            <multiselect
                v-model="filter.status"
                :placeholder="$t('Status')"
                :options="statusOptions"
                label="text"
                trackBy="text"
                multiple
                :preserve-search="true"
                :searchable="true"
                :loading="isLoading"
                :optionsLimit="100"
                @input="onFilter"
            />
        </b-col>
        <b-col sm="6" md="3">
            <custom-date-range-picker v-model="dateRange" />
        </b-col>
    </b-row>
</template>

<script>
import Multiselect from 'vue-multiselect'
import CustomDateRangePicker from '../resources/customDateRangePicker'
import { mapState } from 'vuex'

export default {
    name: 'OrdersFilter',
    props: {
        status: {
            type: Array,
            required: true
        }
    },
    components: { Multiselect, CustomDateRangePicker },
    data () {
        return {
            filter: {
                code: '',
                status: [],
                start_date: null,
                end_date: null
            },
            dateRange: {
                startDate: null,
                endDate: null
            },
            isLoading: false,
            loaded: false
        }
    },
    computed: {
        ...mapState(['navigation']),
        statusOptions () {
            return this.status.map(status => {
                return {
                    value: status,
                    text: this.$t(status)
                }
            })
        }
    },
    watch: {
        dateRange () {
            const { startDate, endDate } = this.dateRange
            this.filter.start_date = startDate
            this.filter.end_date = endDate
            this.onFilter()
        }
    },
    methods: {
        onFilter () {
            this.$emit('onFiltered', this.filter)
        },
        loadDefaultFilter () {
            if ( this.navigation.lastFilter.orders ) {
                const { query } = this.navigation.lastFilter.orders

                for ( const filter of Object.keys(query) ) {
                    if ( filter === 'status' ) {
                        this.filter[filter] = this.statusOptions.filter(({ value }) => query[filter].includes(value))
                    } else {
                        this.filter[filter] = query[filter]
                    }
                }
            }
        }
    },
    mounted () {
        this.loadDefaultFilter()
        this.loaded = true
    }
}
</script>
