<template>
    <div>
        <b-row>
            <b-col :sm="12">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Custom field')} *`"
                >
                    <multiselect
                        v-model="customFieldSelected"
                        :options="customFieldsAvailable"
                        label="name"
                        track-by="id"
                        :placeholder="$t('Custom field')"
                    />
                </b-form-group>
            </b-col>
            <b-col :sm="12">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Value')} *`"
                    label-for="value"
                >
                    <b-form-input
                        id="value"
                        v-model="value"
                        text="value"
                        type="text"
                        :class="{'is-invalid': errors.value}"
                        :disabled="!customFieldSelected"
                    />
                    <div v-if="errors.value" class="invalid-feedback">
                        {{ errors.value[0] }}
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col :sm="6">
                <div v-if="productCustomField">
                    <b-button class="btn btn-danger" @click="onDelete">
                        {{ $t('Delete') }}
                    </b-button>
                </div>
            </b-col>
            <b-col :sm="6">
                <div class="text-end">
                    <b-button class="btn btn-success" :disabled="!customFieldSelected" @click="onSave">
                        {{ $t('Save') }}
                    </b-button>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'
import { mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
    name: 'ProductCustomFieldForm',
    props: {
        product: {
            type: Object,
            required: true
        },
        customFields: {
            type: Array,
            required: true
        },
        productCustomFields: {
            type: Array,
            required: false,
            default: Array
        },
        productCustomField: {
            type: Object,
            required: false,
            default: null
        }
    },
    components: { Multiselect },
    data () {
        return {
            customFieldSelected: this.productCustomField ? this.customFields.find(customField => customField.id === this.productCustomField.custom_field_id): null,
            value: this.productCustomField ? this.productCustomField.value : '',
            errors: {}
        }
    },
    computed: {
        ...mapState(['csrf']),
        customFieldsAvailable () {
            return this.customFields.filter(customField => {
                return customField.multiple_available || !this.productCustomFields.some(productCustomField => productCustomField.custom_field_id === customField.id)
            })
        }
    },
    methods: {
        onDelete () {
            axios.delete(this.$root.route('product-custom-fields.destroy', this.productCustomField.id))
                .then(({ data }) => {
                    this.$emit('refresh')
                    Swal.fire(this.$t(data), '', 'success')
                })
        },
        onSave () {
            const url = this.productCustomField ? this.$root.route('product-custom-fields.update', this.productCustomField.id) : this.$root.route('product-custom-fields.store')
            const axiosAction = this.productCustomField ? axios.put : axios.post

            this.errors = {}

            axiosAction(url, {
                _token: this.csrf,
                product_id: this.product.id,
                custom_field_id: this.customFieldSelected.id,
                value: this.value
            })
                .then(this.onSaveSuccess)
                .catch(this.onSaveFail)
        },
        onSaveSuccess({ data }) {
            this.$emit('refresh')
            Swal.fire(this.$t(data.message), '', 'success')
        },
        onSaveFail ( { response }) {
            const { errors } = response.data

            if ( errors ) {
                this.errors = errors
            }
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
