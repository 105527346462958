<template>
    <Layout>
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">
                        <b-button variant="light" size="sm" :href="$root.route('products.index')">
                            <i class="bx bx-left-arrow" />
                        </b-button>
                        {{ $t(title) }}
                    </h4>
                </div>
            </div>
            <div class="col-12">
                <b-card>
                    <product-form :product="data.product" :categories="data.categories" />
                </b-card>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../../layouts/main'
import ProductForm from '../../../components/shop/products/form'
import ProductVariantsTable from '../../../components/shop/products/variants/table'
import ProductVariantForm from '../../../components/shop/products/variants/form'

export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    components: { ProductForm, ProductVariantsTable, ProductVariantForm, Layout },
    data () {
        return {
            title: 'Products',
            variantData: null
        }
    },
    methods: {
        onCreateVariant () {
            this.variantData = this.data.variantModel
            this.showModal()
        },
        onEditVariant ( variant ) {
            this.variantData = variant
            this.showModal()
        },
        onResetModal () {
            this.variantData = null
            this.hideModal()
        },
        onModalOk () {
            this.variantData = null
            this.$refs.productVariantsTable.refresh()
            this.hideModal()
        },
        showModal () {
            this.$refs.modalVariantForm.show()
        },
        hideModal () {
            this.$refs.modalVariantForm.hide()
        }
    },
    created () {
        this.$store.dispatch('setMarketplaces', this.data.marketplaces)
    }
}
</script>
