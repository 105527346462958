<template>
    <div class="text-center mt-4">
        <h2>Upps! 404. Not found</h2>
    </div>
</template>

<script>
export default {
    name: 'pages-404'
}
</script>
