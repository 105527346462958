<template>
    <b-row v-if="loaded">
        <b-col sm="6" md="3">
            <b-form-input
                v-model="filter.name"
                :placeholder="$t('Product')"
                size="md"
                v-debounce="onFilter"
            />
        </b-col>
        <b-col sm="6" md="2">
            <b-form-input
                v-model="filter.code"
                :placeholder="$t('Code, ASIN, SKU, ERP ID...')"
                size="md"
                v-debounce="onFilter"
            />
        </b-col>
        <b-col sm="12" md="3">
            <select-category
                v-model="categorySelected"
                :categories="categories"
                placeholder="Category"
            />
        </b-col>
        <b-col sm="12" md="2">
            <b-form-select
                v-model="filter.errors"
                :options="productFilterErrorOptions"
                size="md"
                class="form-control"
                @change="onFilter"
            />
        </b-col>
        <b-col sm="12" md="2">
            <b-form-select
                v-model="filter.enable"
                :options="productFilterEnableOptions"
                size="md"
                class="form-control"
                @change="onFilter"
            />
        </b-col>
    </b-row>
</template>

<script>
import SelectCategory from './../categories/select'
import productsMixin from '../../../mixins/products.mixin'
import { mapState } from 'vuex'

export default {
    name: 'ProductsFilter',
    props: {
        categories: {
            type: Array,
            required: false,
            default: []
        }
    },
    components: { SelectCategory },
	mixins: [productsMixin],
    data () {
        return {
            filter: {
                name: '',
                code: '',
                category_id: '',
                errors: 'all',
                enable: 'all'
            },
            categorySelected: null,
            loaded: false,
            isLoading: false,
            productFilterEnableOptions: [
                {
                    value: 'all',
                    text: this.$t('Enables & disables')
                },
                {
                    value: 'on',
                    text: this.$t('Enables')
                },
                {
                    value: 'off',
                    text: this.$t('Disables')
                }
            ]
        }
    },
    computed: {
        ...mapState(['navigation'])
    },
    watch: {
        categorySelected () {
            if ( this.loaded ) {
                this.filter.category_id = this.categorySelected ? this.categorySelected.id : null
                this.onFilter()
            }
        }
    },
    methods: {
        onFilter () {
            this.$emit('onFiltered', this.filter)
        },
        loadDefaultFilter () {
            if ( this.navigation.lastFilter.products ) {
                const { query } = this.navigation.lastFilter.products

                for ( const filter of Object.keys(query) ) {
                    this.filter[filter] = query[filter]

                    if ( filter === 'category_id' ) {
                        this.categorySelected = this.categories.find(category => String(category.id) === String(query[filter])) ?? null
                    }
                }
            }
        }
    },
	mounted () {
		this.filter.errors = this.getDefaultProductFilterErrorsFilter()
        this.loadDefaultFilter()
        this.loaded = true
	}
}
</script>
