<template>
    <b-card
        :bg-variant="bgColor"
        :text-variant="textColor"
    >
        <b-row>
            <b-col sm="12">
                <h4>
                    <i class="bx bxl-import" /> {{ $t('Information') }}
                </h4>
            </b-col>
            <b-col sm="2">
                <b-img
                    :src="product.import_small_image"
                    fluid
                />
            </b-col>
            <b-col sm="10">
                <b-row>
                    <b-col sm="6" md="4">
                        <strong>{{ $t('Stock') }}:</strong>
                        <span v-if="product.import_stock">{{ product.import_stock }}</span>
                        <span v-else>-</span>
                    </b-col>
                    <b-col sm="6" md="4">
                        <strong>{{ $t('Price') }}:</strong>
                        <span v-if="product.import_price">{{ product.import_price }} €</span>
                        <span v-else>-</span>
                    </b-col>
                    <b-col sm="6" md="4">
                        <strong>{{ $t('Color') }}:</strong>
                        <span v-if="product.import_color">{{ product.import_color }}</span>
                        <span v-else>-</span>
                    </b-col>
                    <b-col sm="6" md="4">
                        <strong>{{ $t('Size') }}:</strong>
                        <span v-if="product.import_size">{{ product.import_size }}</span>
                        <span v-else>-</span>
                    </b-col>
                    <b-col sm="6" md="4">
                        <strong>{{ $t('Label') }}:</strong>
                        <span v-if="product.import_label">{{ product.import_label }}</span>
                        <span v-else>-</span>
                    </b-col>
                    <b-col sm="6" md="4">
                        <strong>{{ $t('Model') }}:</strong>
                        <span v-if="product.import_model">{{ product.import_model }}</span>
                        <span v-else>-</span>
                    </b-col>
                    <b-col sm="6" md="4">
                        <strong>{{ $t('Part number') }}:</strong>
                        <span v-if="product.import_part_number">{{ product.import_part_number }}</span>
                        <span v-else>-</span>
                    </b-col>
                    <b-col sm="6" md="4">
                        <strong>{{ $t('Product group') }}:</strong>
                        <span v-if="product.import_product_group">{{ product.import_product_group }}</span>
                        <span v-else>-</span>
                    </b-col>
                    <b-col sm="6" md="4">
                        <strong>{{ $t('Number of items') }}:</strong>
                        <span v-if="product.import_number_of_items">{{ product.import_number_of_items }}</span>
                        <span v-else>-</span>
                    </b-col>
                    <b-col sm="6" md="4">
                        <strong>{{ $t('Package quantity') }}:</strong>
                        <span v-if="product.import_package_quantity">{{ product.import_package_quantity }}</span>
                        <span v-else>-</span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col sm="12">
                <h4>
                    <i class="bx bxl-import" />{{ $t('Item dimensions') }}
                </h4>
            </b-col>
            <b-col sm="6" md="3">
                <strong>{{ $t('Height') }}:</strong>
                <span v-if="product.import_item_dimension_height">
                    {{ product.import_item_dimension_height }} cm
                </span>
                <span v-else>-</span>
            </b-col>
            <b-col sm="6" md="3">
                <strong>{{ $t('Length') }}:</strong>
                <span v-if="product.import_item_dimension_length">
                    {{ product.import_item_dimension_length }} cm
                </span>
                <span v-else>-</span>
            </b-col>
            <b-col sm="6" md="3">
                <strong>{{ $t('Width') }}:</strong>
                <span v-if="product.import_item_dimension_width">
                    {{ product.import_item_dimension_width }} cm
                </span>
                <span v-else>-</span>
            </b-col>
            <b-col sm="6" md="3">
                <strong>{{ $t('Weight') }}:</strong>
                <span v-if="product.import_item_dimension_weight">
                    {{ product.import_item_dimension_weight }} kg
                </span>
                <span v-else>-</span>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col sm="12">
                <h4>
                    <i class="bx bxl-import" />{{ $t('Package dimensions') }}
                </h4>
            </b-col>
            <b-col sm="6" md="3">
                <strong>{{ $t('Height') }}:</strong>
                <span v-if="product.import_package_dimension_height">
                    {{ product.import_package_dimension_height }} cm
                </span>
                <span v-else>-</span>
            </b-col>
            <b-col sm="6" md="3">
                <strong>{{ $t('Length') }}:</strong>
                <span v-if="product.import_package_dimension_length">
                    {{ product.import_package_dimension_length }} cm
                </span>
                <span v-else>-</span>
            </b-col>
            <b-col sm="6" md="3">
                <strong>{{ $t('Width') }}:</strong>
                <span v-if="product.import_package_dimension_width">
                    {{ product.import_package_dimension_width }} cm
                </span>
                <span v-else>-</span>
            </b-col>
            <b-col sm="6" md="3">
                <strong>{{ $t('Weight') }}:</strong>
                <span v-if="product.import_package_dimension_weight">
                    {{ product.import_package_dimension_weight }} kg
                </span>
                <span v-else>-</span>
            </b-col>
        </b-row>
        <template v-if="product.import_material && product.import_material.length">
            <hr>
            <b-row>
                <b-col sm="12">
                    <h4>
                        <i class="bx bxl-import" />{{ $t('Materials') }}
                    </h4>
                </b-col>
                <template v-if="typeof product.import_material === 'object'">
                    <b-col
                        v-for="(material, index) of product.import_material"
                        :key="`material-${index}`"
                        sm="12"
                        md="3"
                    >
                        <i class="bx bxs-circle" />
                        {{ material }}
                    </b-col>
                </template>
                <b-col
                    v-else
                    sm="12"
                    md="3"
                >
                    <i class="bx bxs-circle" />
                    {{ product.import_material }}
                </b-col>
            </b-row>
        </template>
    </b-card>
</template>

<script>
export default {
    name: 'ProductImportDetail',
    props: {
        product: {
            type: Object,
            required: true
        },
        bgColor: {
            type: String,
            required: false,
            default: 'light'
        },
        textColor: {
            type: String,
            required: false,
            default: 'dark'
        }
    }
}
</script>
