<template>
    <div>
        <b-row>
            <b-col sm="6">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Type')} *`"
                    label-for="type"
                >
                    <b-form-select
                        id="type"
                        v-model="connectorData.type"
                        name="type"
                        class="form-control"
                        :class="{'is-invalid': errors.type}"
                    >
                        <b-form-select-option
                            v-for="type in types"
                            :key="type.name"
                            :value="type.name"
                        >
                            {{ type.name }}
                        </b-form-select-option>
                    </b-form-select>
                    <div v-if="errors.type" class="invalid-feedback">
                        {{ errors.type[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="6">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Name')} *`"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="connectorData.name"
                        name="name"
                        :class="{'is-invalid': errors.name}"
                    />
                    <div v-if="errors.name" class="invalid-feedback">
                        {{ errors.name[0] }}
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <template v-if="typeCurrent">
            <hr>
            <div
                v-for="param in typeCurrent.params"
                :key="`type-param-${param.name}`"
            >
                <b-form-group
                    class="mb-3"
                    :label="`${$t(param.label)} *`"
                    :label-for="param.name"
                >
                    <template v-if="param.type === 'token'">
                        <b-form-textarea
                            :id="param.name"
                            v-model="connectorData.params[param.name]"
                            :name="param.name"
                            :class="{'is-invalid': errors[`params.${param.name}`]}"
                        />
                    </template>
					<template v-else-if="param.type === 'select'">
						<b-form-select
							:id="param.name"
							v-model="connectorData.params[param.name]"
							:name="param.name"
							class="form-control"
							:class="{'is-invalid': errors[`params.${param.name}`]}"
						>
							<b-form-select-option
								v-for="option in param.options"
								:key="option.value"
								:value="option.value"
							>
								{{ option.text }}
							</b-form-select-option>
						</b-form-select>
					</template>
                    <template v-else>
                        <b-form-input
                            :id="param.name"
                            v-model="connectorData.params[param.name]"
                            :name="param.name"
                            :class="{'is-invalid': errors[`params.${param.name}`]}"
                        />
                    </template>
                    <div v-if="errors[`params.${param.name}`]" class="invalid-feedback">
                        {{ errors[`params.${param.name}`][0] }}
                    </div>
                </b-form-group>
            </div>
            <hr>
            <div class="text-end">
                <b-button class="btn btn-success" @click="onSave">
                    {{ $t('Save') }}
                </b-button>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    name: 'ConnectorForm',
    props: {
        connector: {
            type: Object,
            required: true
        },
        types: {
            type: Array,
            required: true
        }
    },
    data () {
        const { type, name, params } = this.connector

        return {
            connectorData: { type, name, params },
            errors: {}
        }
    },
    computed: {
        ...mapState(['csrf']),
        isNew () {
            return !this.connector.id
        },
        typeCurrent () {
            return this.types.find(type => type.name === this.connectorData.type)
        }
    },
    watch: {
        typeCurrent () {
            const params = {}

            for ( const param of this.typeCurrent.params ) {
                params[param.name] = ''
            }

            this.connectorData.params = params
        }
    },
    methods: {
        onSave () {
            this.errors = {}

            if ( this.isNew ) {
                axios.post(this.$root.route('connectors.store'), {
                    _token: this.csrf,
                    ...this.connectorData
                })
                    .then(this.onCreateSuccess)
                    .catch(this.onSaveFail)
            } else {
                axios.put( this.$root.route('connectors.update', { connector: this.connector.id }), {
                    _token: this.csrf,
                    ...this.connectorData
                })
                    .then(this.onUpdateSuccess)
                    .catch(this.onSaveFail)
            }
        },
        onCreateSuccess({ data }) {
			const { message, result } = data
            Swal.fire(this.$t(message), '', 'success').then(({}) => {
                window.location.href = this.$root.route('connectors.edit', { connector: result.id })
            })
        },
        onUpdateSuccess ({ data }) {
            Swal.fire(this.$t(data.message), '', 'success').then(({}) => {
                window.location.reload()
            })
        },
        onSaveFail ( { response }) {
            this.errors = response.data.errors
        }
    }
}
</script>
