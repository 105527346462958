export default {
    state: {
        collapse: true
    },
    mutations: {
        SET_COLLAPSE (state, collapse) {
            state.collapse = collapse
        }
    },
    actions: {
        setCollapse({ commit }, collapse) {
            commit('SET_COLLAPSE', collapse)
        }
    }
}
