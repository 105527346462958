<template>
    <div>
        <b-row>
            <b-col v-if="product.import_small_image" sm="12" md="2" class="text-center mb-5">
                <b-img
                    :src="product.import_small_image"
                    fluid
                />
                <div v-if="!hasImportImageDownloaded">
                    <b-button
                        variant="light"
                        size="md"
                        block
                        @click="onDownload"
                    >
                        <i class="bx bxs-download" />
                        {{ $t('Download') }}
                    </b-button>
                </div>
            </b-col>
            <b-col sm="12" :md="product.import_small_image ? 10 : 12">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Name')} *`"
                    label-for="name"
                >
                    <b-form-textarea
                        id="name"
                        v-model="productData.name"
                        name="name"
                        :class="{'is-invalid': errors.name}"
                    />
                    <div v-if="errors.name" class="invalid-feedback">
                        {{ errors.name[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="2">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('SKU')} *`"
                    label-for="sku"
                >
                    <b-form-input
                        id="sku"
                        v-model="productData.sku"
                        name="sku"
                        type="text"
                        :class="{'is-invalid': errors.sku}"
                    />
                    <div v-if="errors.sku" class="invalid-feedback">
                        {{ errors.sku[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="2">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('ASIN')}`"
                    label-for="asin"
                >
                    <b-form-input
                        id="asin"
                        v-model="productData.asin"
                        name="asin"
                        type="text"
                        :class="{'is-invalid': errors.asin}"
                    />
                    <div v-if="errors.asin" class="invalid-feedback">
                        {{ errors.asin[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="2">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Parent ASIN')} `"
                    label-for="parent_asin"
                >
                    <b-form-input
                        id="parent_asin"
                        v-model="productData.parent_asin"
                        name="parent_asin"
                        type="text"
                        :class="{'is-invalid': errors.parent_asin}"
                    />
                    <div v-if="errors.parent_asin" class="invalid-feedback">
                        {{ errors.parent_asin[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="2">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('EAN')}`"
                    label-for="ean"
                >
                    <b-form-input
                        id="ean"
                        v-model="productData.ean"
                        name="ean"
                        type="text"
                        :class="{'is-invalid': errors.ean}"
                    />
                    <div v-if="errors.ean" class="invalid-feedback">
                        {{ errors.ean[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="2">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('ERP ID')}`"
                    label-for="erp_id"
                >
                    <b-form-input
                        id="erp_id"
                        v-model="productData.erp_id"
                        name="erp_id"
                        type="text"
                        :class="{'is-invalid': errors.erp_id}"
                        disabled
                    />
                    <div v-if="errors.erp_id" class="invalid-feedback">
                        {{ errors.erp_id[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="12">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Category')}`"
                    label-for="category"
                >
                    <select-category
                        v-model="categorySelected"
                        :categories="categories"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Manufacture')} *`"
                    label-for="name"
                >
                    <b-form-input
                        id="manufacture_name"
                        v-model="productData.manufacture_name"
                        name="manufacture_name"
                        type="text"
                        :class="{'is-invalid': errors.manufacture_name}"
                    />
                    <div v-if="errors.manufacture_name" class="invalid-feedback">
                        {{ errors.manufacture_name[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Brand')} *`"
                    label-for="manufacture_brand"
                >
                    <b-form-input
                        id="manufacture_brand"
                        v-model="productData.manufacture_brand"
                        name="manufacture_brand"
                        type="text"
                        :class="{'is-invalid': errors.manufacture_brand}"
                    />
                    <div v-if="errors.manufacture_brand" class="invalid-feedback">
                        {{ errors.manufacture_brand[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Short description')}`"
                    label-for="description_short"
                >
                    <text-editor v-model="productData.description_short" />
                </b-form-group>
            </b-col>
            <b-col sm="12">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Description')}`"
                    label-for="description"
                >
                    <text-editor v-model="productData.description" />
                </b-form-group>
            </b-col>
            <b-col sm="12" md="3">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Price')} (Import: ${product.import_price} €)`"
                    label-for="price"
                >
                    <b-form-input
                        id="price"
                        v-model="productData.price"
                        name="price"
                        type="number"
                        :class="{'is-invalid': errors.price}"
                    />
                    <div v-if="errors.price" class="invalid-feedback">
                        {{ errors.price[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="3">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Stock')} (Import: ${product.import_stock})`"
                    label-for="stock"
                >
                    <b-form-input
                        id="stock"
                        v-model="productData.stock"
                        name="stock"
                        type="number"
                        :class="{'is-invalid': errors.stock}"
                    />
                    <div v-if="errors.stock" class="invalid-feedback">
                        {{ errors.stock[0] }}
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <template v-if="!!product.id">
            <hr>
            <product-custom-field :product="product" />
            <hr>
            <product-images-form
                ref="productImages"
                :product="product"
                @refreshed="onRefreshImages"
            />
        </template>
        <hr>
        <div class="text-center">
            <b-button
                variant="link"
                @click="showImportData = !showImportData"
            >
                <template v-if="showImportData">
                    <i class="bx bx-minus" />
                    {{ $t('Hide Import information imported') }}
                </template>
                <template v-else>
                    <i class="bx bx-plus" />
                    {{ $t('Show Import information imported') }}
                </template>
                <i class="bx bxl-import" />
            </b-button>
        </div>
        <b-collapse
            id="import-data"
            v-model="showImportData"
            class="mt-2"
        >
            <product-import-detail :product="product" />
        </b-collapse>
        <hr>
        <div class="text-end">
            <b-button class="btn btn-success" @click="onSave">
                {{ $t('Save') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Swal from 'sweetalert2'
import ProductImagesForm from './images/form'
import ProductCustomField from './product-custom-fields/index'
import SelectCategory from './../categories/select'
import ProductImportDetail from './importDetail'

export default {
    name: 'ProductForm',
    props: {
        product: {
            type: Object,
            required: true
        },
        categories: {
            type: Array,
            required: false,
            default: []
        }
    },
    components: { SelectCategory, ProductImportDetail, ProductImagesForm, ProductCustomField },
    data () {
        const category = this.categories.find(category => category.id === this.product.category_id)

        return {
            productData: this.product,
            categorySelected: category ?? null,
            errors: {},
            showImportData: false,
            hasImportImageDownloaded: false
        }
    },
    computed: {
        ...mapState(['csrf', 'auth']),
        isNew () {
            return !this.product.id
        }
    },
    watch: {
        categorySelected () {
            this.productData.category_id = this.categorySelected.id ?? null
        }
    },
    methods: {
        onSave () {
            this.errors = {}

            if ( this.isNew ) {
                axios.post(this.$root.route('products.store'), {
                    _token: this.csrf,
                    ...this.productData
                })
                    .then(this.onCreateSuccess)
                    .catch(this.onSaveFail)
            } else {
                axios.put(this.$root.route('products.update', this.product.id), {
                    _token: this.csrf,
                    ...this.productData
                })
                    .then(this.onUpdateSuccess)
                    .catch(this.onSaveFail)
            }
        },
        onCreateSuccess({ data }) {
            Swal.fire(this.$t(data.message), '', 'success').then(({}) => {
                window.location.href = this.$root.route('products.edit', { product: data.data.id })
            })
        },
        onUpdateSuccess ({ data }) {
            Swal.fire(this.$t(data.message), '', 'success').then(({}) => {
                window.location.reload()
            })
        },
        onSaveFail ( { response }) {
            const { message, errors } = response.data

            Swal.fire(this.$t(message), '', 'error')

            if ( errors ) {
                this.errors = errors
            }
        },
        onDownload () {
            axios.post(this.$root.route('products.generateImage', this.product.id), {
                _token: this.csrf,
            }).then(({ data }) => {
                this.$refs.productImages.refreshList(data)
            })
        },
        onRefreshImages ( images ) {
            this.hasImportImageDownloaded = images.some(image => image.import_origin)
        }
    }
}
</script>
