<script>
import { mapState } from 'vuex'
import MetisMenu from 'metismenujs/dist/metismenujs'
import { menuItems } from './menu'

/**
 * Side-nav component
 */
export default {
    data() {
        return {
            menuItems: menuItems
        }
    },
    computed: {
        ...mapState(['auth'])
    },
    mounted: function () {
        if ( this.auth ) {
            // eslint-disable-next-line no-unused-vars
            var menuRef = new MetisMenu('#side-menu')
            var links = document.getElementsByClassName('side-nav-link-ref')
            var matchingMenuItem = null
            const paths = []

            for ( var i = 0; i < links.length; i++ ) {
                paths.push(links[ i ][ 'pathname' ])
            }
            var itemIndex = paths.indexOf(window.location.pathname)
            if ( itemIndex === -1 ) {
                const strItems = window.location.pathname.split('/')
                matchingMenuItem = links[ paths.indexOf(`/${strItems[1]}`) ]
            } else {
                matchingMenuItem = links[ itemIndex ]
            }

            if ( matchingMenuItem ) {
                matchingMenuItem.classList.add('active')
                var parent = matchingMenuItem.parentElement

                /**
                 * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
                 * We should come up with non hard coded approach
                 */
                if ( parent ) {
                    parent.classList.add('mm-active')
                    const parent2 = parent.parentElement.closest('ul')
                    if ( parent2 && parent2.id !== 'side-menu' ) {
                        parent2.classList.add('mm-show')

                        const parent3 = parent2.parentElement
                        if ( parent3 ) {
                            parent3.classList.add('mm-active')
                            var childAnchor = parent3.querySelector('.has-arrow')
                            var childDropdown = parent3.querySelector('.has-dropdown')
                            if ( childAnchor ) childAnchor.classList.add('mm-active')
                            if ( childDropdown ) childDropdown.classList.add('mm-active')

                            const parent4 = parent3.parentElement
                            if ( parent4 && parent4.id !== 'side-menu' ) {
                                parent4.classList.add('mm-show')
                                const parent5 = parent4.parentElement
                                if ( parent5 && parent5.id !== 'side-menu' ) {
                                    parent5.classList.add('mm-active')
                                    const childanchor = parent5.querySelector('.is-parent')
                                    if ( childanchor && parent5.id !== 'side-menu' ) {
                                        childanchor.classList.add('mm-active')
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    methods: {
        /**
         * Returns true or false if given menu item has child or not
         * @param item menuItem
         */
        hasItems(item) {
            return item.subItems !== undefined ? item.subItems.length > 0 : false
        },
        getHasAccess ( item ) {
            let access = true

            if ( item.role ) {
                access = this.auth.roles.includes(item.role)
            }

            if ( access && item.permission ) {
                access = this.auth.permissions.includes(item.permission)
            }

            return access
        }
    }
}
</script>

<template>
    <!-- ========== Left Sidebar Start ========== -->

    <!--- Sidemenu -->
    <div v-if="auth" id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul id="side-menu" class="metismenu list-unstyled">
            <template v-for="item in menuItems">
                <template v-if="getHasAccess(item)">
                    <li v-if="item.isTitle" :key="item.id" class="menu-title">{{ $t(item.label) }}</li>
                    <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
                        <a
                            v-if="hasItems(item)"
                            :class="{'has-arrow': !item.badge, 'has-dropdown': item.badge}"
                            class="is-parent"
                            href="javascript:void(0);"
                        >
                            <i v-if="item.icon" :class="`bx ${item.icon}`"></i>
                            <span>{{ $t(item.label) }}</span>
                            <span
                                v-if="item.badge"
                                :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
                            >
                                {{ $t(item.badge.text) }}
                            </span>
                        </a>

                        <a v-if="!hasItems(item)" :href="$root.route(item.link)" class="side-nav-link-ref">
                            <i v-if="item.icon" :class="`bx ${item.icon}`"></i>
                            <span>{{ $t(item.label) }}</span>
                            <span
                                v-if="item.badge"
                                :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
                            >
                                {{ $t(item.badge.text) }}
                            </span>
                        </a>

                        <ul v-if="hasItems(item)" aria-expanded="false" class="sub-menu">
                            <li v-for="(subitem, index) of item.subItems" :key="index">
                                <a
                                    v-if="!hasItems(subitem)"
                                    :href="$root.route(subitem.link)"
                                    class="side-nav-link-ref"
                                >
                                    {{ $t(subitem.label) }}
                                </a>
                                <a
                                    v-if="hasItems(subitem)"
                                    class="side-nav-link-a-ref has-arrow"
                                    href="javascript:void(0);"
                                >
                                    {{ $t(subitem.label) }}
                                </a>
                                <ul v-if="hasItems(subitem)" aria-expanded="false" class="sub-menu mm-collapse">
                                    <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                                        <a :href="$root.route(subSubitem.link)" class="side-nav-link-ref">
                                            {{ $t(subSubitem.label) }}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </template>
            </template>
        </ul>
    </div>
    <!-- Sidebar -->
</template>
