<template>
    <b-form-group
        class="mb-3"
        :label="`${$t(label)} *`"
        :label-for="name"
    >
        <template v-if="type === 'number'">
            <b-form-input
                :id="name"
                v-model="valueBinding"
                :name="name"
                type="number"
                :class="{'is-invalid': errors[`params.${name}`]}"
            />
        </template>
        <template v-else-if="type === 'connector'">
            <b-form-select
                :id="name"
                v-model="valueBinding"
                :name="name"
                class="form-control"
                :class="{'is-invalid': errors[`params.${name}`]}"
            >
                <b-form-select-option
                    v-for="connector in data.connectors"
                    :key="`connector-${connector.id}`"
                    :value="connector.id"
                >
                    {{ connector.name }} ({{ connector.type }})
                </b-form-select-option>
            </b-form-select>
        </template>
        <template v-else-if="type === 'select'">
            <b-form-select
                :id="name"
                v-model="valueBinding"
                :name="name"
                class="form-control"
                :class="{'is-invalid': errors[`params.${name}`]}"
            >
                <b-form-select-option
                    v-for="option in data.options"
                    :key="`connector-${option.value}`"
                    :value="option.value"
                >
                    {{ $t(option.text) }}
                </b-form-select-option>
            </b-form-select>
        </template>
        <template v-else>
            <b-form-input
                :id="name"
                v-model="valueBinding"
                :name="name"
                type="text"
                :class="{'is-invalid': errors[`params.${name}`]}"
            />
        </template>
        <div v-if="errors[`params.${name}`]" class="invalid-feedback">
            {{ errors[`params.${name}`][0] }}
        </div>
    </b-form-group>
</template>

<script>
export default {
    name: 'InputSyncForm',
    props: {
        value: {
            required: true
        },
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        data: {
            required: false
        }
    },
    data () {
        return {
            valueBinding : this.value
        }
    },
    computed: {
        errors () {
            return this.$parent.errors
        }
    },
    watch: {
        valueBinding () {
            this.$emit('input', this.valueBinding)
        }
    }
}
</script>
