import Vue from 'vue'
import Vuex from 'vuex'
import marketplaces from './marketplaces'
import layout from './layout'
import navigation from './navigation'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const dataState = createPersistedState({
    paths: ['layout', 'auth', 'navigation']
})

const index = new Vuex.Store({
    modules: {
        marketplaces,
        layout,
        navigation
    },
    state: {
        auth: null,
        csrf: null
    },
    mutations: {
        SET_AUTH (state, auth) {
            state.auth = auth
        },
        SET_CSRF (state, csrf) {
            state.csrf = csrf
        }
    },
    actions: {
        setAuth ({ commit }, auth) {
            commit('SET_AUTH', auth)
        },
        setCsrf ({ commit }, csrf) {
            commit('SET_CSRF', csrf)
        }
    },
    plugins: [dataState]
})

export default index
