<template>
    <div>
        <products-filter
            :categories="categories"
            @onFiltered="onFiltered"
        />
        <hr />
        <vuetable
            v-if="loaded"
            ref="productsTable"
            :fields="fields"
            :api-url="$root.route('products.data')"
            :append-params="filterQuery"
            :sort-order="sorterOrder"
            count-table-data
            :css="tableCss.table.css"
            :initial-page="initialPage"
            :per-page="perPage"
            :no-data-template="$t('Results not found')"
            pagination-component="vuetable-pagination"
            pagination-path=""
            @vuetable:loading="storeFilter"
            @vuetable:cell-clicked="onCellClicked"
            @vuetable:pagination-data="onTablePaginationData"
        >
            <template slot="enable" slot-scope="props">
                <i
                    class="bx bxs-circle"
                    :class="props.rowData.enable ? 'text-success' : 'text-danger'"
                />
            </template>
            <template slot="image" slot-scope="props">
                <b-img
                    v-if="props.rowData.images && props.rowData.images.length"
                    :src="props.rowData.images[0].url"
                    width="50"
                />
                <span v-else> - </span>
            </template>
            <template slot="ean" slot-scope="props">
                <span v-if="props.rowData.ean && props.rowData.images.ean">
                    {{ props.rowData.ean }}
                </span>
                <span v-else> - </span>
            </template>
            <template slot="name" slot-scope="props">
                {{ props.rowData.name | truncate(100, '...') }}
            </template>
            <template slot="erp_id" slot-scope="props">
                <span v-if="props.rowData.erp_id && props.rowData.images.erp_id">
                    {{ props.rowData.erp_id }}
                </span>
                <span v-else> - </span>
            </template>
            <template slot="category" slot-scope="props">
                <div
                    v-if="props.rowData.category"
                    v-html="props.rowData.category.breadcrumb_linked"
                />
                <span v-else> - </span>
            </template>
            <template slot="errors" slot-scope="props">
                <b-badge
                    :id="`errors-product-${props.rowData.id}`"
                    :variant="props.rowData.errors.length ? 'soft-danger' : 'soft-success'"
                    :class="{'icon-button': props.rowData.errors.length}"
                >
                    <template v-if="props.rowData.errors.length">
                        <span>
                            {{ props.rowData.errors.length }}
                        </span>
                        <b-popover :target="`errors-product-${props.rowData.id}`" variant="danger" triggers="hover" placement="top">
                            <ul>
                                <li
                                    v-for="(error, index) of props.rowData.errors"
                                    :key="`errors-product-${props.rowData.id}-${index}`"
                                >
                                    {{ error }}
                                </li>
                            </ul>
                        </b-popover>
                    </template>
                    <i v-else class="bx bx-check" />
                </b-badge>
            </template>
            <template slot="actions" slot-scope="props">
                <b-button-group>
                    <b-button
                        :variant="props.rowData.id === productDownloading ? 'success' : 'light'"
                        size="md"
                        :disabled="!!productDownloading"
                        @click="downloadProduct(props.rowData.id)"
                    >
                        <i class="bx bx-sync" />
                    </b-button>
                    <b-button
                        variant="primary"
                        size="md"
                        :href="$root.route('products.edit', props.rowData.id)"
                    >
                        <i class="bx bx-pencil" />
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        @click="onDelete(props.rowData.id)"
                    >
                        <i class="bx bx-trash" />
                    </b-button>
                </b-button-group>
            </template>
        </vuetable>
        <b-row>
            <b-col sm="12" md="6">
                <vuetable-pagination-info
                    ref="paginationInfo"
                    :info-template="$t('Displaying {from} to {to} of {total}')"
                    :no-data-template="$t('Results not found')"
                />
            </b-col>
            <b-col sm="12" md="6">
                <pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onTableChangePage"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProductsFilter from './filter'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import Swal from 'sweetalert2'
import axios from 'axios'
import tableCss from '../../../datas/tableCss'
import productsMixin from '../../../mixins/products.mixin'

export default {
    name: 'ProductsTable',
    props: {
        categories: {
            type: Array,
            required: false,
            default: []
        }
    },
    components: { ProductsFilter, Vuetable, VuetablePaginationInfo },
    mixins: [productsMixin],
    data () {
        return {
            tableCss,
            loaded: false,
            filterQuery: {},
            initialPage: 1,
            perPage: 10,
            productDownloading: null
        }
    },
    computed: {
        ...mapState(['csrf', 'auth', 'navigation']),
        fields () {
            return [
                {
                    name: '__slot:enable',
                    title: ''
                },
                {
                    name: '__slot:image',
                    title: ''
                },
                {
                    name: 'sku',
                    title: this.$t('SKU'),
                    sortField: 'sku'
                },
                {
                    name: 'erp_id',
                    title: this.$t('ERP ID'),
                    sortField: 'erp_id'
                },
                {
                    name: '__slot:ean',
                    title: this.$t('EAN'),
                    sortField: 'ean'
                },
                {
                    name: '__slot:name',
                    title: this.$t('Name'),
                    sortField: 'name'
                },
                {
                    name: 'price_parsed',
                    title: this.$t('Price'),
                    sortField: 'price'
                },
                {
                    name: 'stock',
                    title: this.$t('Stock'),
                    sortField: 'stock'
                },
                {
                    name: '__slot:category',
                    title: this.$t('Category')
                },
                {
                    name: '__slot:errors',
                    title: this.$t('Errors')
                },
                {
                    name: '__slot:actions',
                    title: '',
                    dataClass: 'text-end'
                }
            ]
        },
        sorterOrder () {
            return [{
                field: 'name',
                direction: 'asc'
            }]
        }
    },
    methods: {
        ...mapActions({
            setLastFilter: 'setLastFilter'
        }),
        onTablePaginationData (paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.$refs.paginationInfo.setPaginationData(paginationData);
        },
        onTableChangePage (page) {
            this.$refs.productsTable.changePage(page)
        },
        onDelete ( product ) {
            Swal.fire({
                title: this.$t('Are you sure?'),
                text: this.$t('This action remove this product'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes, delete it!'),
                cancelButtonText: this.$t('No, keep it')
            }).then(({ isConfirmed }) => {
                if ( isConfirmed ) {
                    axios.delete(
                        this.$root.route('products.destroy', { product }),
                        {
                            _token: this.csrf,
                        }
                    ).then(this.onDeletedSuccess)
                }
            })
        },
        onDeletedSuccess ({ data }) {
            this.$refs.productsTable.refresh()
            Swal.fire(data, '', 'success')
        },
        onFiltered ({ name, code, category_id, errors, enable }) {
            this.filterQuery = {}

            if ( name ) {
                this.filterQuery.name = name
            }

            if ( code ) {
                this.filterQuery.code = code
            }

            if ( category_id ) {
                this.filterQuery.category_id = category_id
            }

            if ( errors ) {
                this.filterQuery.errors = errors
            }

            if ( enable ) {
                this.filterQuery.enable = enable
            }

            this.$emit('onFilterUpdated', this.filterQuery)
            this.storeFilter()

            setTimeout(() => {
                this.$refs.productsTable.refresh()
            }, 1000)
        },
        onCellClicked ( data, field, event ) {
            this.$refs.productsTable.toggleDetailRow(data.id)
        },
        loadLastFilter () {
            if ( this.navigation.lastFilter.products ) {
                const { query, pagination } = this.navigation.lastFilter.products
                this.filterQuery = query
                this.initialPage = pagination.page
                this.perPage = pagination.perPage
            }
        },
        storeFilter () {
            this.setLastFilter({
                page: 'products',
                filter: {
                    query: this.filterQuery,
                    pagination: {
                        page: this.$refs.productsTable.currentPage,
                        perPage: this.$refs.productsTable.perPage
                    }
                }
            })
        },
        downloadProduct ( product_id ) {
            this.productDownloading = product_id
            this.syncProduct(product_id, this.onDownloadProduct, this.onDownloadProductError)
        },
        onDownloadProduct () {
            this.productDownloading = null
            this.$refs.productsTable.refresh()
        },
        onDownloadProductError () {
            this.productDownloading = null
        }
    },
    mounted () {
        this.filterQuery.errors = this.getDefaultProductFilterErrorsFilter()
        this.loadLastFilter()
        this.$emit('onFilterUpdated', this.filterQuery)
        this.loaded = true
    }
}
</script>
