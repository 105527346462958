<template>
    <div>
        <b-row>
            <b-col sm="12" md="6">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Name')} *`"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="roleData.name"
                        name="name"
                        type="text"
                        :class="{'is-invalid': errors.name}"
                    />
                    <div v-if="errors.name" class="invalid-feedback">
                        {{ errors.name[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
                <b-row>
                    <b-col
                        v-for="permission in roleData.permissions"
                        :key="`permission-${permission.name}`"
                        sm="12"
                        md="4"
                    >
                        <switches
                            v-model="permission.checked"
                            :label="permission.name"
                            theme="bootstrap"
                            :color="permission.checked ? 'success' : 'danger'"
                        />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr>
        <div class="text-end">
            <b-button class="btn btn-success" @click="onSave">
                {{ $t('Save') }}
            </b-button>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'
import axios from 'axios'
import Swal from 'sweetalert2'
import Switches from 'vue-switches'

export default {
    name: 'RoleForm',
    props: {
        role: {
            type: Object,
            required: true
        },
        permissions: {
            type: Array,
            required: true
        }
    },
    components: { Switches },
    data () {
        const { name, permissions } = this.role
        const permissionsChecked = this.permissions.map(({ name }) => {
            return {
                name,
                checked: permissions && permissions.some(permission => permission.name === name)
            }
        })

        return {
            roleData: {
                name,
                permissions: permissionsChecked
            },
            errors: {}
        }
    },
    computed: {
        ...mapState(['csrf']),
        isNew () {
            return !this.role.id
        },
        permissionsChecked () {
            return this.roleData.permissions.filter(permission => permission.checked).map(permission => permission.name)
        }
    },
    methods: {
        onSave () {
            if ( this.isNew ) {
                axios.post(this.$root.route('roles.store'), {
                    _token: this.csrf,
                    name: this.roleData.name,
                    permissions: this.permissionsChecked
                })
                    .then(this.onCreateSuccess)
                    .catch(this.onSaveFail)
            } else {
                axios.put(this.$root.route('roles.update', { role: this.role.id }), {
                    _token: this.csrf,
                    name: this.roleData.name,
                    permissions: this.permissionsChecked
                })
                    .then(this.onUpdateSuccess)
                    .catch(this.onSaveFail)
            }
        },
        onCreateSuccess ({ data }) {
            Swal.fire(this.$t(data.message), '', 'success').then(({}) => {
                window.location.href = this.$root.route('roles.edit', { role: data.data.id })
            })
        },
        onUpdateSuccess ({ data }) {
            Swal.fire(this.$t(data.message), '', 'success').then(({}) => {
                window.location.reload()
            })
        },
        onSaveFail ( { response }) {
            this.errors = response.data.errors
        }
    }
}
</script>
