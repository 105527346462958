<template>
    <div>
        <div v-if="!loaded" class="text-center">
            <b-spinner :label="$t('Loading...')" />
        </div>
        <template v-else-if="items.length">
            <ul class="vertical-timeline list-unstyled">
                <li
                    v-for="(item, index) in items"
                    :key="`item-${index}`"
                    class="event-list"
                >
                    <div class="event-timeline-dot">
                        <i class="bx bx-right-arrow-circle font-size-18" />
                    </div>
                    <div class="media">
                        <div class="me-3">
                            <h5 class="font-size-14">
                                {{ item.date }}
                            </h5>
                        </div>
                        <div class="media-body">
                            <div>
                                <template v-if="item.link">
                                    <a :href="item.link" target="_blank">
                                        {{ item.text | truncate(50, '...') }}
                                    </a>
                                </template>
                                <template v-else>
                                    {{ item.text | truncate(50, '...') }}
                                </template>
                            </div>
                            <b-img-lazy
                                v-if="item.image"
                                :src="item.image"
                                width="75"
                            />
                            <small v-if="item.text_small" class="d-block text-muted">
                                {{ item.text_small }}
                            </small>
                            <b-badge
                                v-if="item.status && variantStatus"
                                :variant="`soft-${getVariantStatus(item.status)}`"
                            >
                                {{ $t(item.status) }}
                            </b-badge>
                        </div>
                    </div>
                </li>
            </ul>
            <hr>
            <div class="text-center">
                <b-button
                    variant="link"
                    size="sm"
                    @click="loadMore"
                >
                    {{ $t('Load more') }}
                </b-button>
            </div>
        </template>
    </div>
</template>

<script>
import dashboardMixin from '../../mixins/dashboard.mixin'

export default {
    name: 'activity',
    props: {
        pagination: {
            type: Number,
            required: false,
            default: 5
        },
        autoRefreshSeconds: {
            type: Number,
            required: false,
            default: null
        },
        variantStatus: {
            type: Object,
            required: false,
            default: null
        }
    },
    mixins: [ dashboardMixin ],
    data () {
        return {
            items: [],
            items_per_page: this.pagination,
            hasMore: false,
            loaded: false
        }
    },
    mounted () {
        this.loadData(this.onSuccess)
        setInterval(() => {
            this.loadData(this.onSuccess)
        }, this.autoRefreshSeconds * 1000)
    },
    methods: {
        onSuccess ({ data }) {
            this.items = data.items
            this.hasMore = data.more
            this.loaded = true
        },
        loadMore () {
            this.items_per_page = this.items_per_page + this.pagination
            this.loadData()
        },
        getVariantStatus ( status ) {
            return this.variantStatus[status] ?? 'info'
        }
    }
}
</script>

<style scoped>
.vertical-timeline {
    border-left: 3px dashed #f6f6f6;
    margin: 0 10px;
}

.vertical-timeline .event-list {
    position: relative;
    padding: 0 0 40px 30px;
}

.vertical-timeline .event-list .event-timeline-dot {
    position: absolute;
    left: -9px;
    top: 0;
    z-index: 9;
    font-size: 16px;
}
</style>
