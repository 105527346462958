<template>
    <apexchart
        v-if="loaded"
        ref="apexChart"
        type="bar"
        :options="apexData.options"
        :series="apexData.chartData"
        :height="apexHeight"
    />
</template>

<script>
import dashboardMixin from '../../mixins/dashboard.mixin'

export default {
    name: 'BarStackedChart',
    props: {
        dateRange: {
            type: Object,
            required: true
        },
        stackedLabels: {
            type: Array,
            required: true
        }
    },
    mixins: [ dashboardMixin ],
    data () {
        return {
            apexHeight: 0,
            apexData: {
                chartData: null,
                options: {
                    chart: {
                        type: 'bar',
                        stacked: true
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true
                        }
                    },
                    xaxis: {
                        categories: []
                    },
                    legend: {
                        position: 'top'
                    },
                    fill: {
                        opacity: 1
                    },
                    colors: ['#f1b54d', '#f1b54d', '#58a9f2', '#58a9f2', '#3fc694', '#f46e6e', '#f46e6e']
                }
            },
            loaded: false
        }
    },
    mounted () {
        this.loadData(this.onSuccess, this.getRoute(this.dateRange))
    },
    methods: {
        getRoute ( dateRange ) {
            const start_date = dateRange.startDate.format('YYYY/MM/DD')
            const end_date = dateRange.endDate.format('YYYY/MM/DD')
            return `${this.route}?start_date=${start_date}&end_date=${end_date}`
        },
        onSuccess ({ data }) {
            this.setData(data)
            this.loaded = true
        },
        setData ( items ) {
            const datasets = []

            for ( const { value, label } of this.stackedLabels ) {
                datasets.push({
                    name: label,
                    data: this.getCountersList(items, value)
                })
            }
            const options = {
                ...this.apexData.options,
                ...{
                    xaxis: {
                        categories: Object.keys(items)
                    }
                }
            }

            this.apexData = {
                ...this.apexData,
                ...{
                    chartData: datasets,
                    options
                }
            }

            this.apexHeight = Object.keys(items).length * 50
        },
        getCountersList ( items, value ) {
            const counters = []

            for ( const [date, item] of Object.entries(items) ) {
                counters.push(item[value] ?? 0)
            }

            return counters
        }
    }
}
</script>
