<script>
import Layout from '../../layouts/main'
import SyncForm from '../../components/syncs/form'

export default {
    props: {
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    components: { Layout, SyncForm },
    data () {
        return {
            title: this.data.setting_title ?? 'Syncs'
        }
    }
}
</script>

<template>
    <Layout>
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">{{ $t(title) }}</h4>
                </div>
            </div>
            <div class="col-12">
                <b-card v-if="data.tabs">
                    <b-tabs  pills card vertical nav-wrapper-class="w-20">
                        <b-tab
                            v-for="tab in data.tabs"
                            :key="`tab-${tab.name}`"
                            :title="`${$t(tab.label)}${!tab.sync_model.enable ? ` (${$t('disable')})` : ''}`"
                        >
                            <sync-form
                                :setting="tab.setting"
                                :sync-model="tab.sync_model"
                                :connectors="tab.data"
                                :extra-form="tab.extra_form"
                                :next-execution="tab.next_execution"
                            />
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>
    </Layout>
</template>
