<template>
    <div>
        <b-row>
            <b-col sm="12" md="4">
                <div class="mb-2">
                    <strong>{{ $t('Status') }}:</strong>
                    <status-badge :status="order.status" />
                </div>
                <div class="mb-2">
                    <strong>{{ $t('Customer') }}:</strong> {{ order.customer.name }}
                </div>
                <div class="mb-2">
                    <strong>{{ $t('Email') }}:</strong> {{ order.customer.email }}
                </div>
                <div class="mb-2">
                    <strong>{{ $t('Phone') }}:</strong> {{ order.customer.phone }}
                </div>
                <div class="mb-2">
                    <strong>{{ $t('Created') }}:</strong> {{ order.created_at_parsed }}
                </div>
                <div class="mb-2">
                    <strong>{{ $t('Last update') }}:</strong> {{ order.updated_at_parsed }}
                </div>
            </b-col>
            <b-col sm="12" md="4">
                <b-row>
                    <b-col sm="12">
                        <div class="mb-2">
                            <strong>{{ $t('Billing address') }}:</strong>
                        </div>
                        <div class="mb-2">{{ order.billing_address }}</div>
                    </b-col>
                    <b-col sm="12">
                        <div class="mb-2">
                            <strong>{{ $t('Shipping address') }}:</strong>
                        </div>
                        <div class="mb-2">{{ order.shipping_address }}</div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col v-if="paymentData !== null" sm="12" md="4">
                <div class="mb-2">
                    <strong>{{ $t('Payment method') }}:</strong> {{ paymentData.payment_method }}
                </div>
                <div class="mb-2">
                    <strong>{{ $t('Currency') }}:</strong> {{ paymentData.currency }}
                </div>
                <div class="mb-2">
                    <strong>{{ $t('Commission') }}:</strong> {{ paymentData.commission }}
                </div>
            </b-col>
        </b-row>
        <hr>
        <b-table
            striped
            :items="items"
            :fields="itemColumns"
        >
        </b-table>
        <div v-if="!hideAmount" class="text-end">
            <strong>{{ $t('Total') }}: {{ order.amount_parsed }}</strong>
        </div>
    </div>
</template>

<script>
import StatusBadge from '../resources/statusBadge'

export default {
    name: 'OrderDetail',
    props: {
        order: {
            type: Object,
            required: true
        },
        hideAmount: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: { StatusBadge },
    computed: {
        items () {
            return this.order.items.map(item => {
                const { units, price_per_unit_parsed, amount_parsed } = item
                const { sku, asin, name } = item.product
                return { sku, asin, name, units, price_per_unit_parsed, amount_parsed }
            })
        },
        itemColumns () {
            return [
                {
                    key: 'sku',
                    label: 'SKU',
                    sortable: true
                },
                {
                    key: 'asin',
                    label: 'ASIN',
                    sortable: true
                },
                {
                    key: 'name',
                    label: this.$t('Name'),
                    sortable: true
                },
                {
                    key: 'units',
                    label: this.$t('Units'),
                    sortable: true
                },
                {
                    key: 'price_per_unit_parsed',
                    label: this.$t('Price per unit'),
                    sortable: true
                },
                {
                    key: 'amount_parsed',
                    label: this.$t('Amount'),
                    sortable: true
                }
            ]
        },
        paymentData () {
            if ( !this.order.import_data || !this.order.import_data.data ) {
                return null
            }

            return this.order.import_data.data.price
        }
    }
}
</script>
