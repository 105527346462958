<template>
    <div>
        <orders-filter
            :status="status"
            @onFiltered="onFiltered"
        />
        <hr />
        <vuetable
            v-if="loaded"
            ref="ordersTable"
            :fields="fields"
            :api-url="$root.route('orders.data')"
            :append-params="filterQuery"
            :sort-order="sorterOrder"
            count-table-data
            :css="tableCss.table.css"
            :initial-page="initialPage"
            :per-page="perPage"
            :no-data-template="$t('Results not found')"
            pagination-component="vuetable-pagination"
            pagination-path=""
            @vuetable:loading="storeFilter"
            @vuetable:cell-clicked="onCellClicked"
            @vuetable:pagination-data="onTablePaginationData"
        >
            <template slot="payment" slot-scope="props">
                <span>
                    {{ props.rowData.import_data.data.price.payment_method }}
                </span>
            </template>
            <template slot="status" slot-scope="props">
                <status-badge :status="props.rowData.status" />
            </template>
            <template slot="tracking" slot-scope="props">
                <template v-if="props.rowData.trackings.length">
                    <div
                        v-for="tracking in props.rowData.trackings"
                        :key="`tracking-${props.rowData.id}-${tracking.code}`"
                    >
                        <b-badge variant="soft-primary">
                            {{ tracking.code }}
                        </b-badge>
                    </div>
                </template>
                <b-badge
                    v-else
                    variant="soft-secondary"
                >
                    {{ $t('Not found') }}
                </b-badge>
            </template>
            <template slot="created" slot-scope="props">
                {{ props.rowData.date | moment(datetimeFormat) }}
            </template>
            <template slot="modified" slot-scope="props">
                {{ props.rowData.import_data.modified | moment(datetimeFormat) }}
            </template>
            <template slot="actions" slot-scope="props">
                <b-button-group>
                    <b-button
                        variant="primary"
                        size="md"
                        @click="$emit('show', props.rowData)"
                    >
                        <i class="bx bx-show" />
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        @click="onDelete(props.rowData.id)"
                    >
                        <i class="bx bx-trash" />
                    </b-button>
                </b-button-group>
            </template>
        </vuetable>
        <b-row>
            <b-col sm="12" md="6">
                <vuetable-pagination-info
                    ref="paginationInfo"
                    :info-template="$t('Displaying {from} to {to} of {total}')"
                    :no-data-template="$t('Results not found')"
                />
            </b-col>
            <b-col sm="12" md="6">
                <pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onTableChangePage"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import OrdersFilter from './filter'
import StatusBadge from '../resources/statusBadge'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import Swal from 'sweetalert2'
import axios from 'axios'
import tableCss from '../../datas/tableCss'
import { datetimeFormat } from '../../constants'

export default {
    name: 'OrdersTable',
    props: {
        status: {
            type: Array,
            required: true
        }
    },
    components: { OrdersFilter, StatusBadge, Vuetable, VuetablePaginationInfo },
    data () {
        return {
            tableCss,
            datetimeFormat,
            filterQuery: {},
            initialPage: 1,
            perPage: 10,
            loaded: false
        }
    },
    computed: {
        ...mapState(['csrf', 'auth', 'navigation']),
        fields () {
            return [
                {
                    name: 'channable_channel_id',
                    title: this.$t('Code'),
                    sortField: 'channable_channel_id'
                },
                {
                    name: 'amount_parsed',
                    title: this.$t('Amount'),
                    sortField: 'amount'
                },
                {
                    name: '__slot:payment',
                    title: this.$t('Payment method')
                },
                {
                    name: '__slot:status',
                    title: this.$t('Status'),
                    sortField: 'status'
                },
                {
                    name: 'channable_channel_name',
                    title: this.$t('Channel'),
                    sortField: 'channable_channel_name'
                },
                {
                    name: '__slot:tracking',
                    title: this.$t('Tracking')
                },
                {
                    name: '__slot:created',
                    title: this.$t('Created'),
                    sortField: 'date'
                },
                {
                    name: '__slot:modified',
                    title: this.$t('Updated'),
                    sortField: 'updated_at'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    dataClass: 'text-end'
                }
            ]
        },
        sorterOrder () {
            return [{
                field: 'date',
                direction: 'desc'
            }]
        },
    },
    methods: {
        ...mapActions({
            setLastFilter: 'setLastFilter'
        }),
        onTablePaginationData (paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.$refs.paginationInfo.setPaginationData(paginationData);
        },
        onTableChangePage (page) {
            this.$refs.ordersTable.changePage(page)
        },
        onDelete ( order ) {
            Swal.fire({
                title: this.$t('Are you sure?'),
                text: this.$t('This action remove this order'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes, delete it!'),
                cancelButtonText: this.$t('No, keep it')
            }).then(({ isConfirmed }) => {
                if ( isConfirmed ) {
                    axios.delete(
                        this.$root.route('orders.destroy', { order }),
                        {
                            _token: this.csrf,
                        }
                    ).then(this.onDeletedSuccess)
                }
            })
        },
        onDeletedSuccess ({ data }) {
            this.$refs.ordersTable.refresh()
            Swal.fire(data, '', 'success')
        },
        onFiltered ({ code, status, start_date, end_date }) {
            this.filterQuery = {}

            if ( code ) {
                this.filterQuery.code = code
            }

            if ( status ) {
                this.filterQuery.status = status.map(item => item.value)
            }

            if ( start_date ) {
                this.filterQuery.start_date = start_date
            }

            if ( end_date ) {
                this.filterQuery.end_date = end_date
            }

            setTimeout(() => {
                this.storeFilter()
                this.$refs.ordersTable.refresh()
            }, 1000)
        },
        onCellClicked ( data, field, event ) {
            this.$refs.ordersTable.toggleDetailRow(data.id)
        },
        loadLastFilter () {
            if ( this.navigation.lastFilter.orders ) {
                const { query, pagination } = this.navigation.lastFilter.orders
                this.filterQuery = query
                this.initialPage = pagination.page
                this.perPage = pagination.perPage
            }
        },
        refresh () {
            this.$refs.ordersTable.refresh()
        },
        storeFilter () {
            this.setLastFilter({
                page: 'orders',
                filter: {
                    query: this.filterQuery,
                    pagination: {
                        page: this.$refs.ordersTable.currentPage,
                        perPage: this.$refs.ordersTable.perPage
                    }
                }
            })
        }
    },
    mounted () {
        this.loadLastFilter()
        this.loaded = true
    }
}
</script>
