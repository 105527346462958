<template>
    <component :is="component_name" :id="id" :data="data" />
</template>

<script>
import i18n from '../i18n'

export default {
    props: {
        component: {
            type: String,
            required: true
        },
        auth: {
            type: Object,
            required: true
        },
        csrf: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false,
            default: () => ""
        },
        data: {
            type: Object,
            required: false,
            default: null
        },
        locale: {
            type: String,
            required: false,
            default: 'en'
        }
    },
    computed: {
        component_name() {
            return !!this.$root.$options.components[this.component] ? this.component : "pages-404";
        }
    },
    created () {
        this.$store.dispatch('setAuth', this.auth)
        this.$store.dispatch('setCsrf', this.csrf)
        i18n.locale = this.locale
        localStorage.setItem('locale', this.locale)
    }
};
</script>
