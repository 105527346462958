<template>
    <date-range-picker
        v-model="dateRange"
        :locale-data="locale"
        show-dropdowns
        auto-apply
        :ranges="false"
        @update="onUpdate"
    />
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    name: 'CustomDateRangePicker',
    props: {
        value: {
            type: Object,
            required: false,
            default: {
                startDate: null,
                endDate: null
            }
        }
    },
    components: { DateRangePicker },
    data() {
        return {
            dateRange: this.value,
            locale: {
                direction: 'ltr',
                format: 'dd/mm/yyyy',
                separator: ' - ',
                applyLabel: this.$t('Apply'),
                cancelLabel: this.$t('Cancel'),
                weekLabel: this.$t('Week'),
                customRangeLabel: this.$t('Custom Range'),
                daysOfWeek: [ this.$t('Sun'), this.$t('Mon'), this.$t('Tue'), this.$t('Wed'), this.$t('Thu'), this.$t('Fri'), this.$t('Sat') ],
                monthNames: [ this.$t('Jan'), this.$t('Feb'), this.$t('Mar'), this.$t('Apr'), this.$t('May'), this.$t('Jun'), this.$t('Jul'), this.$t('Aug'), this.$t('Sep'), this.$t('Oct'), this.$t('Nov'), this.$t('Dec') ],
                firstDay: 1
            }
        }
    },
    methods: {
        onUpdate () {
            this.$emit('input', {
                startDate: this.parseDate(this.dateRange.startDate),
                endDate: this.parseDate(this.dateRange.endDate)
            })
        },
        parseDate ( date ) {
            return this.$moment(date).format('DD/MM/YYYY')
        }
    }
}
</script>
