<template>
    <div>
        <b-row>
            <b-col sm="12">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Name')} *`"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="customFieldData.name"
                        name="name"
                        type="text"
                        :class="{'is-invalid': errors.name || !nameAvailable}"
                        @input="handlerChangeName"
                    />
                    <div v-if="!nameAvailable" class="invalid-feedback">
                        {{ $t('Already exists') }}
                    </div>
                    <div v-else-if="errors.name" class="invalid-feedback">
                        {{ errors.name[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12">
                <b-form-checkbox
                    v-model="customFieldData.multiple_available"
                    name="multiple_available"
                    class="form-check"
                >
                    <span class="pl-4">{{ $t('Multiple available') }}</span>
                </b-form-checkbox>
            </b-col>
        </b-row>
        <hr>
        <div class="text-end">
            <b-button class="btn btn-success" @click="onSave">
                {{ $t('Save') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
    name: 'CustomFieldForm',
    props: {
        customField: {
            type: Object,
            required: false,
            default: null
        }
    },
    data () {
        return {
            customFieldData: {
                name: this.customField ? this.customField.name : '',
                multiple_available: this.customField ? this.customField.multiple_available : false
            },
            nameAvailable: true,
            errors: {}
        }
    },
    computed: {
        ...mapState(['csrf'])
    },
    methods: {
        handlerChangeName () {
            this.nameAvailable = true

            if ( !this.customFieldData.name ) return

            axios.post(this.$root.route('custom-fields.nameIsAvailable'), {
                _token: this.csrf,
                name: this.customFieldData.name,
                custom_field_id: this.customField ? this.customField.id : null
            })
                .then(this.onExists)
        },
        onExists ({ data }) {
            this.nameAvailable = data.available
        },
        onSave () {
            const url = this.customField ? this.$root.route('custom-fields.update', this.customField.id) : this.$root.route('custom-fields.store')
            const axiosAction = this.customField ? axios.put : axios.post
            this.errors = {}

            axiosAction(url, {
                _token: this.csrf,
                ...this.customFieldData
            })
                .then(this.onSaveSuccess)
                .catch(this.onSaveFail)
        },
        onSaveSuccess({ data }) {
            Swal.fire(this.$t(data.message), '', 'success').then(({}) => {
                this.$emit('saved')
            })
        },
        onSaveFail ( { response }) {
            const { errors } = response.data

            if ( errors ) {
                this.errors = errors
            }
        }
    }
}
</script>
