<template>
    <Layout>
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">{{ $t(title) }}</h4>
                </div>
            </div>
        </div>
        <b-row>
            <b-col sm="12" md="3">
                <b-card>
                    <h4 class="card-title">
                        {{ $t('Product status') }}
                    </h4>
                    <donut-chart
                        :route="$root.route('dashboard.products.status')"
                        :colors="['#3fc694', '#f46e6e']"
                        @onLegendClick="handleSelectProductStatus"
                    />
                </b-card>
            </b-col>
            <b-col sm="12" md="6">
                <sales-channels :route="$root.route('dashboard.orders.channel-last-month')" />
            </b-col>
            <b-col v-if="syncs" sm="12" md="3">
                <b-card>
                    <last-sync action="download_products" :syncs="syncs" />
                </b-card>
                <b-card>
                    <last-sync action="sync_products" :syncs="syncs" />
                </b-card>
                <b-card>
                    <last-sync action="download_orders" :syncs="syncs" />
                </b-card>
                <b-card>
                    <last-sync action="sync_orders" :syncs="syncs" />
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12" md="6">
                <b-card>
                    <b-row>
                        <b-col sm="6">
                            <custom-date-range-picker v-model="dateRangeChart1" />
                        </b-col>
                        <b-col sm="6" class="text-end">
                            <h4 class="card-title">
                                {{ $t('Last orders') }}
                            </h4>
                        </b-col>
                    </b-row>
                    <hr>
                    <bar-stacked-chart
                        ref="lastOrdersBars"
                        :route="this.$root.route('dashboard.orders.apex-chart')"
                        :stacked-labels="statusList"
                        :date-range="dateRangeChart1"
                    />
                </b-card>
            </b-col>
            <b-col sm="12" md="6">
                <b-card>
                    <b-row>
                        <b-col sm="6">
                            <custom-date-range-picker v-model="dateRangeChart2" />
                        </b-col>
                        <b-col sm="6" class="text-end">
                            <h4 class="card-title">
                                {{ $t('Amount orders') }}
                            </h4>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col sm="12">
                            <h4 class="card-title">
                                {{ $t('Total amount') }}
                            </h4>
                            <line-chart
                                ref="totalAmountOrdersLines"
                                :route="this.$root.route('dashboard.orders.amount-line-chart')"
                                :stacked-labels="[{value: 'totalAmount', label: this.$t('Total amount')}]"
                                :date-range="dateRangeChart2"
                            />
                        </b-col>
                        <b-col sm="12">
                            <h4 class="card-title">
                                {{ $t('Average amount and max') }}
                            </h4>
                            <line-chart
                                ref="amountOrdersLines"
                                :route="this.$root.route('dashboard.orders.amount-line-chart')"
                                :stacked-labels="amountLabels"
                                :date-range="dateRangeChart2"
                            />
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </Layout>
</template>

<script>
import Layout from '../layouts/main'
import Activity from '../components/dashboard/activity'
import BarStackedChart from '../components/dashboard/barStackedChart'
import LineChart from '../components/dashboard/lineChart'
import CustomDateRangePicker from '../components/resources/customDateRangePicker'
import statusMixin from '../mixins/status.mixin'
import DonutChart from '../components/dashboard/donutChart'
import LastSync from '../components/dashboard/lastSync'
import SalesChannels from '../components/dashboard/salesChannels'
import axios from 'axios'

export default {
    components: { DonutChart, BarStackedChart, LineChart, Layout, Activity, CustomDateRangePicker, LastSync, SalesChannels },
    mixins: [ statusMixin ],
    data () {
        return {
            title: 'Dashboard',
            statusList: [
                {
                    value: 'created',
                    label: this.$t('created')
                },
                {
                    value: 'accepted',
                    label: this.$t('accepted')
                },
                {
                    value: 'processed',
                    label: this.$t('processed')
                },
                {
                    value: 'shipping',
                    label: this.$t('shipping')
                },
                {
                    value: 'received',
                    label: this.$t('received')
                },
                {
                    value: 'refund',
                    label: this.$t('refund')
                },
                {
                    value: 'cancelled',
                    label: this.$t('cancelled')
                }
            ],
            amountLabels: [
                {
                    value: 'averageAmount',
                    label: this.$t('Average amount')
                },
                {
                    value: 'maxAmount',
                    label: this.$t('Max. amount')
                }
            ],
            dateRangeChart1: {
                startDate: this.$moment().subtract(1, 'weeks'),
                endDate: this.$moment()
            },
            dateRangeChart2: {
                startDate: this.$moment().subtract(1, 'weeks'),
                endDate: this.$moment()
            },
            syncs: null
        }
    },
    watch: {
        dateRangeChart1 () {
            const format = 'DD-MM-YYYY'

            this.$refs.lastOrdersBars.loadData({
                startDate: this.$moment(this.dateRangeChart1.startDate, format),
                endDate: this.$moment(this.dateRangeChart1.endDate, format)
            })
        },
        dateRangeChart2 () {
            const format = 'DD-MM-YYYY'

            const dateRange = {
                startDate: this.$moment(this.dateRangeChart2.startDate, format),
                endDate: this.$moment(this.dateRangeChart2.endDate, format)
            }

            this.$refs.totalAmountOrdersLines.loadData(dateRange)
            this.$refs.amountOrdersLines.loadData(dateRange)
        }
    },
    methods: {
        handleSelectProductStatus ( status ) {
            window.location.href = this.$root.route('products.index', { status })
        },
        loadSyncs () {
            axios.get(this.$root.route('dashboard.syncs')).then(({ data }) => {
                this.syncs = data
            })
        }
    },
    mounted () {
        this.loadSyncs()
    }
}
</script>
