<template>
    <b-badge :variant="variant">
        {{ $t(status) }}
    </b-badge>
</template>

<script>
import statusMixin from '../../mixins/status.mixin'

export default {
    name: 'statusBadge',
    props: {
        status: {
            type: String,
            required: true
        }
    },
    mixins: [ statusMixin ],
    computed: {
        variant () {
            return `soft-${this.getVariantStatus(this.status)}`
        }
    }
}
</script>
