<template>
    <div>
        <b-row>
            <b-col sm="12" md="4">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Email')} *`"
                    label-for="email"
                >
                    <b-form-input
                        id="email"
                        v-model="userData.email"
                        name="email"
                        type="email"
                        :class="{'is-invalid': errors.email}"
                    />
                    <div v-if="errors.email" class="invalid-feedback">
                        {{ errors.email[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="4">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Name')} *`"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="userData.name"
                        name="name"
                        type="text"
                        :class="{'is-invalid': errors.name}"
                    />
                    <div v-if="errors.name" class="invalid-feedback">
                        {{ errors.name[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="4">
                <b-form-group
                    class="mb-3"
                    :label="`${$t('Password')}${isNew ? ' *' : ''}`"
                    label-for="password"
                >
                    <b-form-input
                        id="password"
                        v-model="userData.password"
                        name="password"
                        type="password"
                        :class="{'is-invalid': errors.name}"
                    />
                    <div v-if="errors.password" class="invalid-feedback">
                        {{ errors.password[0] }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col
                v-for="role in roles"
                :key="`role-${role.name}`"
                sm="6"
                md="2"
            >
                <b-form-radio
                    v-model="userData.role"
                    name="role"
                    :value="role.name"
                    :disabled="auth.id === user.id"
                >
                    <span class="ms-2">{{ role.name }}</span>
                </b-form-radio>
            </b-col>
        </b-row>
        <div v-if="errors.role" class="alert alert-danger">
            {{ errors.role[0] }}
        </div>
        <hr>
        <div class="text-end">
            <b-button class="btn btn-success" @click="onSave">
                {{ $t('Save') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    name: 'UserForm',
    props: {
        user: {
            type: Object,
            required: true
        },
        roles: {
            type: Array,
            required: true
        }
    },
    data () {
        const { id, email, name } = this.user
        const role = this.user.roles.length ? this.user.roles[0].name : ''

        return {
            userData: {
                user: id,
                email,
                name,
                password: '',
                role
            },
            errors: {}
        }
    },
    computed: {
        ...mapState(['csrf', 'auth']),
        isNew () {
            return !this.user.id
        },
        itsMe () {
            return this.auth.id === this.user.id
        },
        updateUrl () {
            return this.itsMe ? this.$root.route('profile.update') : this.$root.route('users.update', { user: this.user.id })
        }
    },
    methods: {
        onSave () {
            this.errors = {}

            if ( this.isNew ) {
                axios.post(this.$root.route('users.store'), {
                    _token: this.csrf,
                    ...this.userData
                })
                    .then(this.onCreateSuccess)
                    .catch(this.onSaveFail)
            } else {
                axios.put(this.updateUrl, {
                    _token: this.csrf,
                    ...this.userData
                })
                    .then(this.onUpdateSuccess)
                    .catch(this.onSaveFail)
            }
        },
        onCreateSuccess({ data }) {
            Swal.fire(this.$t(data.message), '', 'success').then(({}) => {
                window.location.href = this.$root.route('users.edit', { user: data.data.id })
            })
        },
        onUpdateSuccess ({ data }) {
            Swal.fire(this.$t(data.message), '', 'success').then(({}) => {
                window.location.reload()
            })
        },
        onSaveFail ( { response }) {
            this.errors = response.data.errors
        }
    }
}
</script>
