<script>
/**
 * Forgot Password component
 */
export default {
    data() {
        return {
            email: '',
            tryingToReset: false,
            isResetError: false
        }
    },
    mounted() {
        this.isResetError = !!this.error
        this.tryingToReset = !!this.status
    },
    props: {
        submitUrl: {
            type: String,
            required: true
        },
        error: {
            type: String,
            required: false,
            default: () => null
        },
        status: {
            type: String,
            required: false,
            default: () => null
        }
    }
}
</script>

<template>
    <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
                <div class="bg-soft bg-primary">
                    <div class="row">
                        <div class="col-7">
                            <div class="text-primary p-4">
                                <h5 class="text-primary">{{ $t('Reset Password') }}</h5>
                            </div>
                        </div>
                        <div class="col-5 align-self-end">
                            <img alt class="img-fluid" src="/images/profile-img.png"/>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <!--
                    <div>
                        <div class="avatar-md profile-user-wid mb-4">
                        <span class="avatar-title rounded-circle bg-light">
                            <img alt height="34" src="/images/logo.svg"/>
                        </span>
                        </div>
                    </div>
                    -->
                    <div class="p-2">
                        <b-alert v-model="isResetError" class="mb-4" dismissible variant="danger">{{ $t(error) }}</b-alert>
                        <b-alert v-model="tryingToReset" class="mb-4" dismissible variant="success">{{ $t(status) }}
                        </b-alert>
                        <form :action="submitUrl" method="POST">
                            <slot/>
                            <div class="form-group">
                                <label for="useremail">Email</label>
                                <input id="useremail" v-model="email" :placeholder="$t('Enter email')" class="form-control" name="email"
                                       type="email"/>
                            </div>
                            <div class="mt-3 d-grid">
                                <b-button class="btn-block" type="submit" variant="primary">
                                    {{ $t('Reset') }}
                                </b-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="mt-5 text-center">
                <p>
                    {{ $t('Remember It ?') }}
                    <a :href="$root.route('login')" class="fw-medium text-primary">{{ $t('Sign In here') }}</a>
                </p>
            </div>
        </div>
    </div>
</template>
