<template>
    <ul class="pagination pagination-rounded pagination-sm float-end">
        <li class="page-item" :class="{'disabled': isOnFirstPage}">
            <a href="" class="page-link" @click.prevent="loadPage(1)">
                <i class="bx bx-chevrons-left" />
            </a>
        </li>

        <li class="page-item" :class="{'disabled': isOnFirstPage}">
            <a class="page-link" href="" @click.prevent="loadPage('prev')">
                <i class="bx bx-chevron-left" />
            </a>
        </li>

        <template v-if="notEnoughPages">
            <li v-for="n in totalPage" class="page-item" :class="{'active': isCurrentPage(n)}">
                <a href="" class="page-link" @click.prevent="loadPage(n)" v-html="n"></a>
            </li>
        </template>
        <template v-else>
            <li v-for="n in windowSize" class="page-item" :class="{'active': isCurrentPage(windowStart+n-1)}">
                <a href="" class="page-link" @click.prevent="loadPage(windowStart+n-1)" v-html="windowStart+n-1"></a>
            </li>
        </template>

        <li class="page-item" :class="{'disabled': isOnLastPage}">
            <a class="page-link" href="" @click.prevent="loadPage('next')">
                <i class="bx bx-chevron-right" />
            </a>
        </li>

        <li class="page-item" :class="{'disabled': isOnLastPage}">
            <a href="" class="page-link" @click.prevent="loadPage(totalPage)">
                <i class="bx bx-chevrons-right" />
            </a>
        </li>
    </ul>
</template>

<script>
import VuetablePaginationMixin from 'vuetable-2/src/components/VuetablePaginationMixin'

export default {
    name: 'pagination',
    mixins: [VuetablePaginationMixin]
}
</script>
