<template>
    <b-row>
        <b-col sm="12" md="12">
            <strong class="card-title">
                {{ label }}
            </strong>
        </b-col>
        <template v-if="sync">
            <b-col sm="12">
				<template v-if="sync">
					<b-badge :variant="origin !== 'undefined' ? 'soft-primary' : 'soft-secondary'">
						{{ $t(origin)}}
					</b-badge>
				</template>
            </b-col>
            <b-col sm="12" md="6">
                {{ sync.start_time | moment(datetimeFormat) }}
            </b-col>
            <b-col sm="12" md="6" class="text-end">
                <div
                    v-for="(data, iData) of sync.data"
                    :key="`sync-param-${iData}`"
                >
                    {{ $t(iData) }}:
                    <b-badge variant="soft-success">
                        {{ data }}
                    </b-badge>
                </div>
            </b-col>
        </template>
        <template v-else>
            <b-col sm="12">
                <span class="text-muted">{{ $t('Results not found') }}</span>
            </b-col>
        </template>
    </b-row>
</template>

<script>
import { datetimeFormat } from '../../constants'

export default {
    name: 'lastSync',
    props: {
        action: {
            type: String,
            required: true
        },
        syncs: {
            type: Object,
            required: false,
            default: null
        }
    },
    data () {
        return {
            datetimeFormat
        }
    },
    computed: {
        label () {
            switch ( this.action ) {
                case 'download_products':
                    return this.$t('Last download products')
                case 'sync_products':
                    return this.$t('Last sync products')
                case 'download_orders':
                    return this.$t('Last download orders')
                case 'sync_orders':
                    return this.$t('Last sync orders')
                default:
                    return ''
            }
        },
        origin () {
            if ( !this.sync || !this.sync.origin ) {
                return 'undefined'
            }

            return this.sync.origin
        },
        sync () {
            return this.syncs[this.action] ?? null
        }
    }
}
</script>
