<script>
import Layout from '../../../layouts/main'
import ProductDetail from '../../../components/shop/products/detail'
import ProductVariantsTable from '../../../components/shop/products/variants/table'
import ProductVariantForm from '../../../components/shop/products/variants/form'

export default {
    props: {
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    components: { ProductDetail, ProductVariantsTable, ProductVariantForm, Layout },
    data() {
        return {
            title: 'Product Variants',
            variantData: null
        }
    },
    methods: {
        onCreateVariant () {
            this.variantData = this.data.variantModel
            this.showModal()
        },
        onEditVariant ( variant ) {
            this.variantData = variant
            this.showModal()
        },
        onResetModal () {
            this.variantData = null
            this.hideModal()
        },
        onModalOk () {
            this.variantData = null
            this.$refs.productVariantsTable.refresh()
            this.hideModal()
        },
        showModal () {
            this.$refs.modalVariantForm.show()
        },
        hideModal () {
            this.$refs.modalVariantForm.hide()
        }
    }
}
</script>

<template>
    <Layout>
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">{{ $t(title) }}</h4>
                </div>
            </div>
            <div class="col-12">
                <b-card>
                    <product-detail :product="data.product" />
                </b-card>
                <b-card>
                    <div class="text-end">
                        <b-button
                            variant="primary"
                            size="sm"
                            @click="onCreateVariant"
                        >
                            <i class="bx bx-plus" />
                        </b-button>
                    </div>
                    <product-variants-table
                        ref="productVariantsTable"
                        :product="data.product"
                        @edit="onEditVariant"
                    />
                </b-card>
            </div>
        </div>
        <b-modal
            ref="modalVariantForm"
            size="lg"
            hide-header
            hide-footer
            @hidden="onResetModal"
        >
            <product-variant-form
                v-if="variantData"
                :variant="variantData"
                @refresh="onModalOk"
                @cancel="onResetModal"
            />
        </b-modal>
    </Layout>
</template>
