<template>
    <div>
        <vuetable
            ref="variantsTable"
            :fields="fields"
            :api-url="$root.route('variants.data')"
            :append-params="filterQuery"
            count-table-data
            :css="tableCss.table.css"
            :per-page="10"
            :no-data-template="$t('Results not found')"
            pagination-component="vuetable-pagination"
            pagination-path=""
            @vuetable:pagination-data="onTablePaginationData"
        >
            <template slot="skus" slot-scope="props">
            <span
                v-for="sku in props.rowData.skus"
                :key="`sku-${sku.value}`"
                class="badge badge-pill badge-soft-primary me-2"
            >
                {{ sku.value }}
            </span>
            </template>
            <template slot="actions" slot-scope="props">
                <b-button-group>
                    <b-button
                        variant="primary"
                        size="md"
                        @click="$emit('edit', props.rowData)"
                    >
                        <i class="bx bx-pencil" />
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        @click="onDelete(props.rowData.id)"
                    >
                        <i class="bx bx-trash" />
                    </b-button>
                </b-button-group>
            </template>
        </vuetable>
        <b-row>
            <b-col sm="12" md="6">
                <vuetable-pagination-info
                    ref="paginationInfo"
                    :info-template="$t('Displaying {from} to {to} of {total}')"
                    :no-data-template="$t('Results not found')"
                />
            </b-col>
            <b-col sm="12" md="6">
                <pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onTableChangePage"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import tableCss from '../../../../datas/tableCss'
import Swal from 'sweetalert2'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    name: 'ProductVariantsTable',
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    components: { Vuetable, VuetablePaginationInfo },
    data () {
        return {
            tableCss
        }
    },
    computed: {
        ...mapState(['csrf', 'auth']),
        fields () {
            return [
                {
                    name: 'id',
                    title: '#'
                },
                {
                    name: 'asin',
                    title: this.$t('Name'),
                    sortField: 'name'
                },
                {
                    name: 'stock',
                    title: this.$t('Stock'),
                    sortField: 'stock'
                },
                {
                    name: 'price_parsed',
                    title: this.$t('Price'),
                    sortField: 'price'
                },
                {
                    name: '__slot:skus',
                    title: 'SKU'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    dataClass: 'text-end'
                }
            ]
        },
        filterQuery () {
            return {
                product: this.product.id
            }
        }
    },
    methods: {
        refresh () {
            this.$refs.variantsTable.refresh()
        },
        onTablePaginationData (paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.$refs.paginationInfo.setPaginationData(paginationData);
        },
        onTableChangePage (page) {
            this.$refs.variantsTable.changePage(page)
        },
        onDelete ( variant ) {
            Swal.fire({
                title: this.$t('Are you sure?'),
                text: this.$t('This action remove this variant'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes, delete it!'),
                cancelButtonText: this.$t('No, keep it')
            }).then(({ isConfirmed }) => {
                if ( isConfirmed ) {
                    axios.delete(
                        this.$root.route('variants.destroy', { variant }),
                        {
                            _token: this.csrf,
                        }
                    ).then(this.onDeletedSuccess)
                }
            })
        },
        onDeletedSuccess ({ data }) {
            this.refresh()
            Swal.fire(data, '', 'success')
        },
    }
}
</script>
