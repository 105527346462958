import axios from 'axios'
import { mapState } from 'vuex'

export default {
    props: {
        route: {
            type: String,
            required: true
        },
        method: {
            type: String,
            required: false,
            default: 'GET'
        }
    },
    computed: {
        ...mapState([ 'csrf' ])
    },
    methods: {
        loadData ( onSuccess, url = null ) {
            const data = {
                _token: this.csrf
            }

            switch ( this.method ) {
                case 'POST':
                    axios.post(url ?? this.route, data).then(onSuccess)
                    break
                case 'PUT':
                    axios.put(url ?? this.route, data).then(onSuccess)
                    break
                default:
                    axios.get(url ?? this.route).then(onSuccess)
            }
        }
    }
}
