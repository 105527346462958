<template>
    <apexchart
        v-if="loaded"
        ref="apexChart"
        type="line"
        :options="lineData.options"
        :series="lineData.chartData"
        :height="400"
    />
</template>

<script>
import dashboardMixin from '../../mixins/dashboard.mixin'

export default {
    name: 'lineChart',
    props: {
        dateRange: {
            type: Object,
            required: true
        },
        stackedLabels: {
            type: Array,
            required: true
        }
    },
    mixins: [ dashboardMixin ],
    data () {
        return {
            lineData: {
                chartData: null,
                options: {
                    chart: {
                        type: 'line',
                        stacked: true,
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true
                        }
                    },
                    xaxis: {
                        categories: []
                    },
                    legend: {
                        position: 'top'
                    },
                    fill: {
                        opacity: 1
                    }
                }
            },
            loaded: false
        }
    },
    mounted () {
        this.loadData(this.onSuccess, this.getRoute(this.dateRange))
    },
    methods: {
        getRoute ( dateRange ) {
            const start_date = dateRange.startDate.format('YYYY/MM/DD')
            const end_date = dateRange.endDate.format('YYYY/MM/DD')
            return `${this.route}?start_date=${start_date}&end_date=${end_date}`
        },
        onSuccess ({ data }) {
            this.setData(data)
            this.loaded = true
        },
        setData ( items ) {
            const datasets = []

            for ( const { value, label } of this.stackedLabels ) {
                datasets.push({
                    name: label,
                    type: value === 'totalAmount' ? 'area' : 'line',
                    data: this.getCountersList(items, value)
                })
            }
            const options = {
                ...this.lineData.options,
                ...{
                    xaxis: {
                        categories: Object.keys(items)
                    }
                }
            }

            this.lineData = {
                ...this.lineData,
                ...{
                    chartData: datasets,
                    options
                }
            }
        },
        getCountersList ( items, value ) {
            const counters = []

            for ( const [date, item] of Object.entries(items) ) {
                counters.push(item[value] ?? 0)
            }

            return counters
        }
    }
}
</script>
