<template>
    <div>
        <vuetable
            ref="usersTable"
            :fields="fields"
            :api-url="$root.route('users.data')"
            :sort-order="sorterOrder"
            count-table-data
            :css="tableCss.table.css"
            :per-page="10"
            :no-data-template="$t('Results not found')"
            pagination-component="vuetable-pagination"
            pagination-path=""
            @vuetable:pagination-data="onTablePaginationData"
        >
            <template slot="roles" slot-scope="props">
                {{ props.rowData.id}}
            </template>
            <template slot="actions" slot-scope="props">
                <b-button-group>
                    <b-button
                        variant="primary"
                        size="md"
                        :href="$root.route('users.edit', { user: props.rowData.id })"
                        :disabled="props.rowData.id === auth.id"
                    >
                        <i class="bx bx-pencil" />
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        :disabled="props.rowData.id === auth.id"
                        @click="onDelete(props.rowData.id)"
                    >
                        <i class="bx bx-trash" />
                    </b-button>
                </b-button-group>
            </template>
        </vuetable>
        <b-row>
            <b-col sm="12" md="6">
                <vuetable-pagination-info
                    ref="paginationInfo"
                    :info-template="$t('Displaying {from} to {to} of {total}')"
                    :no-data-template="$t('Results not found')"
                />
            </b-col>
            <b-col sm="12" md="6">
                <pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onTableChangePage"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import Swal from 'sweetalert2'
import axios from 'axios'
import tableCss from '../../datas/tableCss'

export default {
    name: 'UsersTable',
    components: { Vuetable, VuetablePaginationInfo },
    data () {
        return {
            tableCss
        }
    },
    computed: {
        ...mapState(['csrf', 'auth']),
        fields () {
            return [
                {
                    name: 'id',
                    title: '#'
                },
                {
                    name: 'name',
                    title: this.$t('Name'),
                    sortField: 'name'
                },
                {
                    name: 'email',
                    title: this.$t('Email'),
                    sortField: 'email'
                },
                {
                    name: '__slot:roles',
                    title: this.$t('Roles')
                },
                {
                    name: '__slot:actions',
                    title: '',
                    dataClass: 'text-end'
                }
            ]
        },
        sorterOrder () {
            return [{
                field: 'name',
                direction: 'asc'
            }]
        }
    },
    methods: {
        onTablePaginationData (paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.$refs.paginationInfo.setPaginationData(paginationData);
        },
        onTableChangePage (page) {
            this.$refs.usersTable.changePage(page)
        },
        onDelete ( user ) {
            Swal.fire({
                title: this.$t('Are you sure?'),
                text: this.$t('This action remove this user'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes, delete it!'),
                cancelButtonText: this.$t('No, keep it')
            }).then(({ isConfirmed }) => {
                if ( isConfirmed ) {
                    axios.delete(
                        this.$root.route('users.destroy', { user }),
                        {
                            _token: this.csrf,
                        }
                    ).then(this.onDeletedSuccess)
                }
            })
        },
        onDeletedSuccess ({ data }) {
            this.$refs.usersTable.refresh()
            Swal.fire(data, '', 'success')
        }
    }
}
</script>
