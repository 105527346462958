<template>
    <Layout>
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <b-row>
                    <b-col sm="12" md="8">
                        <div class="page-title-box d-flex align-items-center justify-content-between">
                            <h4 class="mb-0 font-size-18">{{ $t(title) }}</h4>
                        </div>
                    </b-col>
                    <b-col sm="12" md="4" class="text-end">
                        <import-products-button />
                        <export-button
                            ref="exportButton"
                            :url="$root.route('products.export')"
                        />
                    </b-col>
                </b-row>
            </div>
            <div class="col-12">
                <b-card>
                    <!--
                    <div class="row">
                        <div class="col-xs-6 text-end">
                            <b-button variant="primary" size="sm" :href="$root.route('products.create')">
                                <i class="bx bx-plus" />
                            </b-button>
                        </div>
                    </div>
                    -->

                    <products-table
                        :categories="data.categories"
                        @onFilterUpdated="handleFilterUpdated"
                    />
                </b-card>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../../layouts/main'
import ProductsTable from '../../../components/shop/products/table'
import ImportProductsButton from '../../../components/resources/importProductsButton.vue'
import ExportButton from '../../../components/resources/exportButton'

export default {
    props: {
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    components: { ImportProductsButton, ExportButton, ProductsTable, Layout },
    data () {
        return {
            title: 'Products'
        }
    },
    methods: {
        handleFilterUpdated ( filter ) {
            this.$refs.exportButton.updateFilter(filter)
        }
    }
}
</script>
