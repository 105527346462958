<script>
import { mapState } from 'vuex'
import i18n from '../i18n'
import axios from 'axios'
//import simplebar from 'simplebar-vue'

/**
 * Nav-bar Component
 */
export default {
    data() {
        return {
            languages: [
                {
                    flag: '/images/flags/us.jpg',
                    language: 'en',
                    title: this.$t('English')
                },
                {
                    flag: '/images/flags/spain.jpg',
                    language: 'es',
                    title: this.$t('Spanish')
                }
            ],
            lan: i18n.locale,
            text: null,
            flag: null,
            value: null
        }
    },
    //components: { simplebar }
    computed: {
        ...mapState(['auth', 'csrf'])
    },
    mounted () {
        this.value = this.languages.find((x) => x.language === i18n.locale)
        this.text = this.value.title
        this.flag = this.value.flag
    },
    methods: {
        toggleMenu() {
            this.$parent.toggleMenu()
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar()
        },
        initFullScreen() {
            document.body.classList.toggle('fullscreen-enable')
            if (
                !document.fullscreenElement &&
                /* alternative standard method */ !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if ( document.documentElement.requestFullscreen ) {
                    document.documentElement.requestFullscreen()
                } else if ( document.documentElement.mozRequestFullScreen ) {
                    document.documentElement.mozRequestFullScreen()
                } else if ( document.documentElement.webkitRequestFullscreen ) {
                    document.documentElement.webkitRequestFullscreen(
                        Element.ALLOW_KEYBOARD_INPUT
                    )
                }
            } else {
                if ( document.cancelFullScreen ) {
                    document.cancelFullScreen()
                } else if ( document.mozCancelFullScreen ) {
                    document.mozCancelFullScreen()
                } else if ( document.webkitCancelFullScreen ) {
                    document.webkitCancelFullScreen()
                }
            }
        },
        setLanguage(locale, country, flag) {
            this.lan = locale
            this.text = country
            this.flag = flag
            i18n.locale = locale
            localStorage.setItem('locale', locale)
            this.setLanguageRequest(locale)
        },
        setLanguageRequest ( locale ) {
            axios.post(this.$root.route('locale', locale), {
                _token: this.csrf
            }).then(() => {
                window.location.reload()
            })
        }
    }
}
</script>

<template>
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box bg-white">
                    <a class="logo logo-dark" href="/">
                        <span class="logo-sm">
                          <img src="/images/logo-naturhogar.png" alt="Naturhogar" style="width: 80%" />
                        </span>
                        <span class="logo-lg">
                            <img src="/images/logo-naturhogar.png" alt="Naturhogar" style="width: 80%" />
                        </span>
                    </a>

                    <a class="logo logo-light" href="/">
                        <span class="logo-sm">
                          <img src="/images/logo-naturhogar.png" alt="Naturhogar" style="width: 80%" />
                        </span>
                        <span class="logo-lg">
                          <img src="/images/logo-naturhogar.png" alt="Naturhogar" style="width: 80%" />
                        </span>
                    </a>
                </div>

                <button
                    id="vertical-menu-btn"
                    class="btn btn-sm px-3 font-size-16 header-item"
                    type="button"
                    @click="toggleMenu"
                >
                    <i class="fa fa-fw fa-bars"></i>
                </button>
            </div>

            <div class="d-flex">

                <b-dropdown right toggle-class="header-item" variant="white">
                    <template v-slot:button-content>
                        <img :src="flag" alt="Header Language" class height="16"/>
                        {{ text }}
                    </template>
                    <b-dropdown-item
                        v-for="(entry, i) in languages"
                        :key="`Lang${i}`"
                        :class=" {'active' : lan === entry.language}"
                        :value="entry"
                        class="notify-item"
                        @click="setLanguage(entry.language, entry.title, entry.flag)"
                    >
                        <img :src="`${entry.flag}`" alt="user-image" class="me-1" height="12"/>
                        <span class="align-middle">{{ entry.title }}</span>
                    </b-dropdown-item>
                </b-dropdown>

                <div class="dropdown d-none d-lg-inline-block ms-1">
                    <button class="btn header-item noti-icon" type="button" @click="initFullScreen">
                        <i class="bx bx-fullscreen"></i>
                    </button>
                </div>

                <!--
                <b-dropdown
                    menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
                    right
                    toggle-class="header-item noti-icon"
                    variant="black"
                >
                    <template v-slot:button-content>
                        <i class="bx bx-bell bx-tada"></i>
                        <span
                            class="badge bg-danger rounded-pill"
                        >
                            {{ $t('navbar.dropdown.notification.badge') }}
                        </span>
                    </template>

                    <div class="p-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0">{{ $t('navbar.dropdown.notification.text') }}</h6>
                            </div>
                            <div class="col-auto">
                                <a class="small" href="#">{{ $t('navbar.dropdown.notification.subtext') }}</a>
                            </div>
                        </div>
                    </div>
                    <simplebar style="max-height: 230px;">
                        <a class="text-reset notification-item" href="javascript: void(0);">
                            <div class="media">
                                <div class="avatar-xs me-3">
                                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                                        <i class="bx bx-cart"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">{{ $t('navbar.dropdown.notification.order.title') }}</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">{{ $t('navbar.dropdown.notification.order.text') }}</p>
                                        <p class="mb-0">
                                            <i class="mdi mdi-clock-outline"></i>
                                            {{ $t('navbar.dropdown.notification.order.time') }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a class="text-reset notification-item" href="javascript: void(0);">
                            <div class="media">
                                <img
                                    alt="user-pic"
                                    class="me-3 rounded-circle avatar-xs"
                                    src="/images/users/avatar-3.jpg"
                                />
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">{{ $t('navbar.dropdown.notification.james.title') }}</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">{{ $t('navbar.dropdown.notification.james.text') }}</p>
                                        <p class="mb-0">
                                            <i class="mdi mdi-clock-outline"></i>
                                            {{ $t('navbar.dropdown.notification.james.time') }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a class="text-reset notification-item" href="javascript: void(0);">
                            <div class="media">
                                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="bx bx-badge-check"></i>
                  </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">{{ $t('navbar.dropdown.notification.item.title') }}</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">{{ $t('navbar.dropdown.notification.item.text') }}</p>
                                        <p class="mb-0">
                                            <i class="mdi mdi-clock-outline"></i>
                                            {{ $t('navbar.dropdown.notification.item.time') }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a class="text-reset notification-item" href="javascript: void(0);">
                            <div class="media">
                                <img
                                    alt="user-pic"
                                    class="me-3 rounded-circle avatar-xs"
                                    src="/images/users/avatar-4.jpg"
                                />
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">{{ $t('navbar.dropdown.notification.salena.title') }}</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">{{ $t('navbar.dropdown.notification.salena.text') }}</p>
                                        <p class="mb-0">
                                            <i class="mdi mdi-clock-outline"></i>
                                            {{ $t('navbar.dropdown.notification.salena.time') }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </simplebar>
                    <div class="p-2 border-top d-grid">
                        <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
                            <i class="mdi mdi-arrow-down-circle me-1"></i>
                            <span key="t-view-more"> {{ $t('navbar.dropdown.notification.button') }} </span>
                        </a>
                    </div>
                </b-dropdown>
                -->

                <template v-if="auth">
                    <b-dropdown menu-class="dropdown-menu-end" right toggle-class="header-item" variant="black">
                        <template v-slot:button-content>
                            <span class="d-none d-xl-inline-block ms-1">{{ auth.name }}</span>
                            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                        </template>
                        <!-- item-->

                        <b-dropdown-item :href="$root.route('profile')">
                            <i class="bx bx-user font-size-16 align-middle me-1"></i>
                            {{ $t('navbar.dropdown.profile') }}
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <a class="dropdown-item text-danger" :href="$root.route('logout')">
                            <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                            {{ $t('navbar.dropdown.logout') }}
                        </a>
                    </b-dropdown>
                </template>
            </div>
        </div>
    </header>
</template>
