<template>
    <b-row>
        <b-col sm="6" md="3">
            <strong>{{ $t('Name') }}:</strong> {{ product.name }}
        </b-col>
        <b-col sm="6" md="3">
            <strong>{{ $t('Category') }}:</strong>
            <a v-if="product.category" :href="$root.route('categories.edit', product.category.id)">{{ product.category.name }}</a>
            <span v-else> - </span>
        </b-col>
        <b-col sm="6" md="3">
            <strong>{{ $t('Manufacture') }}:</strong> {{ product.manufacture_name }}
        </b-col>
        <b-col sm="6" md="3">
            <strong>{{ $t('Brand') }}:</strong> {{ product.manufacture_brand }}
        </b-col>
        <b-col sm="12" class="mt-4">
            <strong>{{ $t('Description') }}:</strong>
            <div v-html="product.description" />
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'ProductDetail',
    props: {
        product: {
            type: Object,
            required: true
        }
    }
}
</script>
