<template>
    <b-button
        variant="outline-primary"
        size="sm"
        @click="importProducts"
    >
        <i class="bx bx-sync font-size-18" />
    </b-button>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
    name: 'ImportProductsButton',
    computed: {
        ...mapState(['csrf']),
    },
    methods: {
        importProducts () {
            axios.post(this.$root.route('products.import'), { _token: this.csrf })
                .then(({ data }) => {
                    const { message } = data
                    Swal.fire(this.$t(message), '', 'success')
                })
                .catch(({ response }) => {
                    const { message } = response.data
                    Swal.fire(this.$t(message), '', 'error')
                })
        }
    }
}
</script>
