<script>
import Layout from '../../layouts/main'
import UsersTable from '../../components/user/table'

export default {
    props: {
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    components: { UsersTable, Layout },
    data() {
        return {
            title: 'Users'
        }
    }
}
</script>

<template>
    <Layout>
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">{{ $t(title) }}</h4>
                </div>
            </div>
            <div class="col-12">
                <b-card>
                    <div class="text-end">
                        <b-button variant="primary" size="md" :href="$root.route('users.create')">
                            <i class="bx bx-plus" />
                        </b-button>
                    </div>
                    <users-table />
                </b-card>
            </div>
        </div>
    </Layout>
</template>
